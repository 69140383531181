import { gql } from '@apollo/client';
import { SITE_MENUS_FRAGMENT } from 'data/fragments/menus';

export const UPDATE_SITE_MENUS_MUTATION = gql`
    mutation UPDATE_SITE_MENUS($columnId: String!, $date: String!, $locationsToUpdate: [LocationToUpdateInput!]!) {
        updateSiteMenus(columnId: $columnId, date: $date, locationsToUpdate: $locationsToUpdate) {
            ...SiteMenusFragment
        }
    }
    ${SITE_MENUS_FRAGMENT}
`;

export const GENERATE_MENUS_SUPPLY_ORDERS_MUTATION = gql`
    mutation GENERATE_MENUS_SUPPLY_ORDERS(
        $date: String!
        $siteIds: [String!]
        $supplierIds: [String!]
        $updateOrderProductIfPossible: Boolean
    ) {
        generateMenusSupplyOrders(
            date: $date
            siteIds: $siteIds
            supplierIds: $supplierIds
            updateOrderProductIfPossible: $updateOrderProductIfPossible
        ) {
            success
            error
        }
    }
`;

export const GENERATE_QUANTITIES_MUTATION = gql`
    mutation GENERATE_QUANTITIES($date: String!) {
        generateQuantities(date: $date) {
            success
            error
        }
    }
`;

export const GENERATE_SITE_MENUS_MUTATION = gql`
    mutation GENERATE_SITE_MENUS(
        $date: String!
        $stockMin: Int
        $consumptionRatioToApply: Int
        $unknownShrinkageRatioToApply: Int
        $withoutRotation: Boolean!
    ) {
        generateSiteMenus(
            date: $date
            stockMin: $stockMin
            consumptionRatioToApply: $consumptionRatioToApply
            unknownShrinkageRatioToApply: $unknownShrinkageRatioToApply
            withoutRotation: $withoutRotation
        ) {
            success
            error
        }
    }
`;
