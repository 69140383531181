import { DEFAULT_B2B_RANGE_ID, DEFAULT_B2C_RANGE_ID } from 'constants/prices';
import { defaultProduct } from 'pages/Products/constants/defaultProduct';
import {
    CONDITIONAL_FIELDS,
    CUSTOM_CREATION_FIELDS,
    CUSTOM_FIELDS,
    ID,
    OPTIONAL_CREATION_FIELDS,
    OPTIONAL_UPDATE_FIELDS,
    REQUIRED_CREATION_FIELDS,
    REQUIRED_UPDATE_FIELDS,
} from 'pages/Products/constants/csvFields';

import {
    formatProductField,
    isIdField,
    splitStringIntoArray,
    splitStringIntoArrayKeepCase,
} from 'pages/Products/ProductImports/helpers/utils';
import { ImportType } from 'pages/Products/ProductImports/CsvImport';
import { PriceRangeType } from 'data/__generated__';

type CsvDataRow = Array<string>;
type ProductToModifyType = any;

const SUPPLIER = 'supplier';
const NUTRITION = 'nutrition';
const PRICEB2 = 'priceB2';
const DELIVERY_DAYS = 'deliveryDays';
const ALLERGEN = 'allergen';
const CROSSEDPRICEB2 = 'crossedPriceB2';

export function formatProductCsvData({ csvData, importType }: { csvData: CsvDataRow[]; importType: ImportType }) {
    const [headerValues, ...data] = csvData;
    const lowercaseHeader = headerValues.map((value) => value.toLowerCase().trim());

    const productFields = (
        importType === ImportType.CREATION
            ? [...REQUIRED_CREATION_FIELDS, ...CONDITIONAL_FIELDS, ...OPTIONAL_CREATION_FIELDS]
            : [...REQUIRED_UPDATE_FIELDS, ...CONDITIONAL_FIELDS, ...OPTIONAL_UPDATE_FIELDS]
    ).map(({ name }: { name: string }) => name);

    const customFields =
        importType === ImportType.CREATION ? [...CUSTOM_FIELDS, ...CUSTOM_CREATION_FIELDS] : CUSTOM_FIELDS;

    const productFieldsLowerCase = productFields.map((productField) => productField.toLowerCase());

    //update header names to match the DB fields
    const formattedHeader = lowercaseHeader.map((headerField) => {
        const index = productFieldsLowerCase.findIndex((elem) => elem === headerField.toLowerCase());

        return index !== -1 ? productFields[index] : isIdField(headerField) ? ID : headerField;
    });

    return data.map((productArrayFromCSV: CsvDataRow) => {
        const productToModify: ProductToModifyType =
            importType === ImportType.CREATION
                ? {
                      suppliers: [...defaultProduct.suppliers],
                      allergen: { ...defaultProduct.allergen },
                      deliveryDays: { ...defaultProduct.deliveryDays },
                      nutrition: { ...defaultProduct.nutrition },
                      productPrices: [],
                      toleranceMin: defaultProduct.toleranceMin,
                      toleranceMax: defaultProduct.toleranceMax,
                  }
                : {};

        formattedHeader.forEach((headerField, index) => {
            const formattedProductField = formatProductField(headerField, productArrayFromCSV[index]);
            if (headerField === 'searchKeywords') {
                productToModify.searchKeywords = splitStringIntoArray(formattedProductField);
                return;
            }
            if (!customFields.includes(headerField.toLowerCase())) {
                productToModify[headerField] = formattedProductField;
            } else if (headerField.startsWith(SUPPLIER)) {
                const supplierField = headerField.toLowerCase().substring(SUPPLIER.length);
                if (!productToModify.suppliers?.length) {
                    productToModify.suppliers = [
                        {
                            name: '',
                            _id: '',
                            code: '',
                            prices: [
                                {
                                    value: 0,
                                    conditionning: 0,
                                    packagesPerBatch: 0,
                                },
                            ],
                        },
                    ];
                }
                switch (supplierField) {
                    case 'id':
                        productToModify.suppliers[0]._id = formattedProductField;
                        return;
                    case 'price':
                        productToModify.suppliers[0].prices[0].value = formattedProductField;
                        return;
                    case 'conditionning':
                        productToModify.suppliers[0].prices[0].conditionning = formattedProductField;
                        return;
                    case 'packagesperbatch':
                        productToModify.suppliers[0].prices[0].packagesPerBatch = formattedProductField;
                        return;
                    case 'code':
                        productToModify.suppliers[0].code = formattedProductField;
                        return;
                    default:
                        return;
                }
            } else if (headerField.startsWith(NUTRITION)) {
                const substring = headerField.substring(NUTRITION.length);
                const nutrient = substring.charAt(0).toLowerCase() + substring.slice(1);
                if (!productToModify.nutrition) {
                    productToModify.nutrition = {};
                }
                productToModify.nutrition[nutrient] = formattedProductField;
            } else if (headerField.startsWith(ALLERGEN)) {
                if (headerField === 'allergenCanContain') {
                    productToModify.allergen.canContain = formattedProductField;
                } else {
                    const allergens = splitStringIntoArray(formattedProductField);
                    if (!productToModify.allergen) {
                        const { canContain, ...restOfAllergens } = defaultProduct.allergen;
                        productToModify.allergen = restOfAllergens;
                    }
                    allergens.forEach((allergen: string) =>
                        allergen ? (productToModify.allergen[allergen] = true) : null,
                    );
                }
            } else if (headerField === DELIVERY_DAYS) {
                const noDeliveryDays = splitStringIntoArray(formattedProductField);
                if (!productToModify.deliveryDays) {
                    productToModify.deliveryDays = { ...defaultProduct.deliveryDays };
                }
                noDeliveryDays.forEach((day: string) => (day ? (productToModify.deliveryDays[day] = false) : null));
            } else if (['badgeIds', 'tagIds', 'barcodes'].includes(headerField)) {
                productToModify[headerField] = splitStringIntoArrayKeepCase(formattedProductField);
            } else if (headerField.startsWith(PRICEB2)) {
                if (!productToModify.productPrices) {
                    productToModify.productPrices = [];
                }
                const storeType = headerField.substring(PRICEB2.length);

                const productPriceToAdd: {
                    price: string | number | boolean | null;
                    rangeId: string;
                    type: PriceRangeType;
                    crossedPrice?: string | number | null;
                } = {
                    price: formattedProductField,
                    rangeId: storeType === 'B' ? DEFAULT_B2B_RANGE_ID : DEFAULT_B2C_RANGE_ID,
                    type: PriceRangeType.Product,
                };

                const crossedPriceIndex = formattedHeader.findIndex(
                    (elem) => elem.startsWith(CROSSEDPRICEB2) && elem.endsWith(storeType),
                );

                if (crossedPriceIndex !== -1) {
                    const crossedPrice = formatProductField(
                        formattedHeader[crossedPriceIndex],
                        productArrayFromCSV[crossedPriceIndex],
                    );

                    productPriceToAdd.crossedPrice = crossedPrice === null ? null : Number(crossedPrice);
                }

                productToModify.productPrices.push(productPriceToAdd);
            }
        });
        return productToModify;
    });
}
