import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { Link, useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import styled from 'styled-components';
import { FaSave, FaTrash } from 'react-icons/fa';

import { colors } from 'constants/colors';

import { GET_PRODUCT_PRICE_RANGE_QUERY } from 'data/queries/price';

import { apolloClient } from '../../App';
import {
    ProductArrangementEntry,
    ProductEntry,
    ProductPriceRangeType,
    SelectedProduct,
    SelectedProductArrangement,
} from './ProductPriceRangeCreate';
import { ProductsPriceTable } from './ProductsPriceTable';
import { PriceRangeProductSelector } from './PriceRangeProductSelector';

import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemCsvInputButton, CsvDataRow } from 'components/TotemCsvInputButton';

import { checkProductRangeCsvImport } from '../../helpers/CsvCheckers/checkProductRangeCsvImport';
import { DEFAULT_RANGE_IDS } from 'constants/prices';
import { PriceRangeProductArrangementSelector } from './PriceRangeProductArrangementSelector';
import { ProductArrangementsPriceTable } from './ProductArrangementsPriceTable';
import {
    useGetProductPriceRangeQuery,
    useGetProductsFromCsvLazyQuery,
    useUpdatePriceRangeMutation,
} from 'data/__generated__';
type ParamTypes = {
    rangeId: string;
};

export const ProductPriceRangeDetails = () => {
    const { rangeId = '' } = useParams<ParamTypes>();
    const [csvData, setCsvData] = useState<Array<CsvDataRow>>([]);
    const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);
    const [selectedProductArrangements, setSelectedProductArrangements] = useState<SelectedProductArrangement[]>([]);
    const {
        loading: priceRangeLoading,
        data: priceRangeData,
        error: priceRangeError,
    } = useGetProductPriceRangeQuery({
        variables: {
            rangeId,
        },
    });

    const [getProductsFromCsv, { loading: loadingCsv }] = useGetProductsFromCsvLazyQuery({
        fetchPolicy: 'cache-and-network',
        onCompleted({ productsFromCsv }) {
            const {
                productsForRange,
                idsNotFound,
                idsNotActive,
                productArrangementsForRange,
                productArrangementsIdsNotFound,
                productArrangementsIdsNotActive,
            } = productsFromCsv;
            const productsToAddToSelection = [];
            const productArrangementsToAddToSelection = [];
            const existingProductValues = getValues('products');
            const existingProductArrangementValues = getValues('productArrangements');

            for (const product of productsForRange) {
                const { _id, name, price } = product;
                if (price) {
                    if (selectedProducts.some(({ productId }) => productId === _id)) {
                        const index = existingProductValues.map(({ productId }) => productId).indexOf(_id);
                        setValue(`products.${index}.price` as 'products.0.price', price, { shouldDirty: true });
                    } else {
                        productsToAddToSelection.push({ productId: _id, name });
                        appendProduct({ productId: _id, price } as ProductEntry);
                    }
                }
            }
            setSelectedProducts([...selectedProducts, ...productsToAddToSelection]);

            for (const productArrangement of productArrangementsForRange) {
                const { _id, name, price } = productArrangement;
                if (price) {
                    if (selectedProductArrangements.some(({ productArrangementId }) => productArrangementId === _id)) {
                        const index = existingProductArrangementValues
                            .map(({ productArrangementId }) => productArrangementId)
                            .indexOf(_id);
                        setValue(`productArrangements.${index}.price` as 'productArrangements.0.price', price, {
                            shouldDirty: true,
                        });
                    } else {
                        productArrangementsToAddToSelection.push({ productArrangementId: _id, name });
                        appendProductArrangement({ productArrangementId: _id, price } as ProductArrangementEntry);
                    }
                }
            }
            setSelectedProductArrangements([...selectedProductArrangements, ...productArrangementsToAddToSelection]);

            if (idsNotFound.length) {
                let message = 'Ids des produits pas trouvés: ';
                message += idsNotFound.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }

            if (idsNotActive.length) {
                let message = 'Ids des produits en état non-actif: ';
                message += idsNotActive.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }

            if (productArrangementsIdsNotFound.length) {
                let message = 'Ids des gammes de produits pas trouvés: ';
                message += productArrangementsIdsNotFound.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }

            if (productArrangementsIdsNotActive.length) {
                let message = 'Ids des gammes de produits en état non-actif: ';
                message += productArrangementsIdsNotActive.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }
        },
    });

    const [updatePriceRange, { loading: updatePriceRangeLoading }] = useUpdatePriceRangeMutation();

    const methods = useForm<ProductPriceRangeType>({ shouldUnregister: false });
    const {
        control,
        getValues,
        formState: { isDirty },
        handleSubmit,
        register,
        reset,
        setValue,
    } = methods;
    const {
        fields: productsFields,
        append: appendProduct,
        remove: removeProduct,
    } = useFieldArray({ control, name: 'products' });
    const {
        fields: productArrangementsFields,
        append: appendProductArrangement,
        remove: removeProductArrangement,
    } = useFieldArray({ control, name: 'productArrangements' });

    useEffect(() => {
        if (priceRangeData?.productPriceRange) {
            const { products, productArrangements } = priceRangeData.productPriceRange;
            const previouslySelectedProducts = products.map(({ productId, name }) => ({ productId, name }));
            setSelectedProducts(previouslySelectedProducts);
            const selectedPriceProducts = products.map(({ productId, price, crossedPrice }) => ({
                productId,
                price,
                crossedPrice,
            }));

            const previouslySelectedProductArrangements = productArrangements.map(({ productArrangementId, name }) => ({
                productArrangementId,
                name,
            }));
            setSelectedProductArrangements(previouslySelectedProductArrangements);
            const selectedPriceProductArrangements = productArrangements.map(
                ({ productArrangementId, price, crossedPrice }) => ({
                    productArrangementId,
                    price,
                    crossedPrice,
                }),
            );

            reset({ products: selectedPriceProducts, productArrangements: selectedPriceProductArrangements });
        }
    }, [priceRangeData, appendProduct, appendProductArrangement, reset]);

    const handleCSVImport = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        const { success, error } = checkProductRangeCsvImport({ csvData });
        if (!success) {
            setCsvData([]);
            toast.error(error, { autoClose: false });
            return;
        }

        const [header, ...data] = csvData;
        const productIdIndex = header.findIndex((elem) => elem === 'productId');
        const productArrangementIdIndex = header.findIndex((elem) => elem === 'productArrangementId');
        const priceIndex = header.findIndex((elem) => elem === 'price');

        const csvDataFormatted = data.map((rowArray) => {
            const price = Number(rowArray[priceIndex].replace(',', '.'));
            return {
                productId: rowArray[productIdIndex],
                productArrangementId: rowArray[productArrangementIdIndex],
                price,
            };
        });

        await getProductsFromCsv({
            variables: {
                csvData: csvDataFormatted,
            },
        });
    };

    function handleRemoveProduct(index: number): void {
        removeProduct(index);
        setSelectedProducts(selectedProducts.filter((_, selectedProductIndex) => selectedProductIndex !== index));
    }

    function handleRemoveProductArrangement(index: number): void {
        removeProductArrangement(index);
        setSelectedProductArrangements(
            selectedProductArrangements.filter(
                (_, selectedProductArrangementIndex) => selectedProductArrangementIndex !== index,
            ),
        );
    }

    const onSubmit = handleSubmit(async (formData) => {
        try {
            const { rangeName, products, productArrangements } = formData;

            if (!products?.length && !productArrangements?.length) {
                toast.error('Une gamme doit contenir au moins 1 produit ou 1 gamme de produit');
                return null;
            }

            reset({ products, productArrangements });

            console.log('products', products);
            console.log('productArrangements', productArrangements);

            const result = await updatePriceRange({
                variables: { rangeName, rangeId, products, productArrangements },
            });

            const priceRangeResult = result?.data?.updatePriceRange;

            if (priceRangeResult) {
                const { success, error } = priceRangeResult;
                if (!success && error) {
                    toast.error(error);
                } else {
                    const productsInCache = products.map(({ productId, price }) => {
                        const productName = productsFields.find((field) => field.productId === productId)?.name;
                        return { productId, price, name: productName };
                    });

                    const productArrangementsInCache = productArrangements.map(({ productArrangementId, price }) => {
                        const productArrangementName = productArrangementsFields.find(
                            (field) => field.productArrangementId === productArrangementId,
                        )?.name;
                        return {
                            productArrangementId: productArrangementId,
                            price: price,
                            name: productArrangementName,
                        };
                    });

                    const data = {
                        productPriceRange: {
                            name: rangeName,
                            products: productsInCache,
                            productArrangements: productArrangementsInCache,
                        },
                    };

                    apolloClient.writeQuery({
                        query: GET_PRODUCT_PRICE_RANGE_QUERY,
                        variables: {
                            rangeId,
                        },
                        data,
                    });

                    toast.success('La gamme a été modifiée !');
                }
            }
        } catch (error) {
            toast.error('Une erreur est survenue.');
        }
    });

    if (priceRangeLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (priceRangeError || !priceRangeData) {
        toast.error('Une erreur est survenue lors de la récupération de la gamme');
        return null;
    }

    const { productPriceRange } = priceRangeData;
    const isDefaultRange = DEFAULT_RANGE_IDS.includes(rangeId);

    return (
        <FormProvider {...methods}>
            <Form onSubmit={onSubmit}>
                <Header>
                    <HeaderTitle>{productPriceRange?.name}</HeaderTitle>
                    <ButtonsContainer>
                        {!isDefaultRange ? (
                            <>
                                {csvData.length ? (
                                    <ButtonsContainer>
                                        <TotemPrimaryButton onClick={() => setCsvData([])}>
                                            <FaTrash data-test="trash-icon" size={12} color={colors.pureWhite} />
                                        </TotemPrimaryButton>
                                        <TotemPrimaryButton minWidth="105px" onClick={(e) => handleCSVImport(e)}>
                                            {loadingCsv || updatePriceRangeLoading ? (
                                                <Loader size="18px" mode={LoaderModeType.Spin} />
                                            ) : (
                                                'Import CSV'
                                            )}
                                        </TotemPrimaryButton>
                                    </ButtonsContainer>
                                ) : (
                                    <TotemCsvInputButton onCsvDataUpload={setCsvData} />
                                )}
                                <TotemPrimaryButton type="submit" disabled={!isDirty}>
                                    {loadingCsv || updatePriceRangeLoading ? (
                                        <Loader size="18px" mode={LoaderModeType.Spin} />
                                    ) : (
                                        <>
                                            <FaSave size="13" />
                                            <SaveLabel>Mettre à jour</SaveLabel>
                                        </>
                                    )}
                                </TotemPrimaryButton>
                            </>
                        ) : null}
                        <Link to="/productPriceRanges">
                            <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                        </Link>
                    </ButtonsContainer>
                </Header>
                <Content>
                    <Fields>
                        <DetailFormValue
                            label="Nom de la gamme"
                            placeholder="Gamme A, prix Pantin, prix Murex ..."
                            defaultValue={productPriceRange?.name}
                            disabled={isDefaultRange}
                            width="100%"
                            {...register('rangeName' as const, { required: true })}
                        />
                        {isDefaultRange ? (
                            <div>
                                <b>NB:</b> Les champs dans les gammes par défaut ne sont pas modifiables. Veuillez
                                mettre les prix des produits à jour soit individuellement sur leur propres pages
                                produit, soit par
                                <StyledLink to="/product/imports">import csv</StyledLink>
                                pour plusieurs produits (champs priceB2B, crossedPriceB2B ou priceB2C, crossedPriceB2C).
                            </div>
                        ) : null}
                        <PriceRangeProductSelector
                            append={appendProduct}
                            selectedProducts={selectedProducts}
                            setSelectedProducts={setSelectedProducts}
                            shouldDisplaySelect={!isDefaultRange}
                        />
                        <PriceRangeProductArrangementSelector
                            append={appendProductArrangement}
                            selectedProductArrangements={selectedProductArrangements}
                            setSelectedProductArrangements={setSelectedProductArrangements}
                            shouldDisplaySelect={!isDefaultRange}
                        />
                    </Fields>
                    {productsFields.length ? (
                        <ProductsPriceTable
                            selectedProducts={selectedProducts}
                            fields={productsFields}
                            removeProduct={handleRemoveProduct}
                            rangeId={rangeId}
                        />
                    ) : null}
                    {productArrangementsFields.length ? (
                        <ProductArrangementsPriceTable
                            selectedProductArrangements={selectedProductArrangements}
                            fields={productArrangementsFields}
                            removeProductArrangement={handleRemoveProductArrangement}
                        />
                    ) : null}
                </Content>
            </Form>
        </FormProvider>
    );
};

const SaveLabel = styled.span`
    margin-left: 5px;
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: max-content;
    align-items: center;

    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Container = styled.div`
    display: flex;
    flex: 1;
    width: 100%;
    background: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex: 1;
    padding: 15px;
    overflow-y: hidden;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    background: ${({ theme }) => theme.backgroundColor};
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const StyledLink = styled(Link)`
    color: ${({ theme }) => theme.ctaPrimaryHoveredColor};
    margin: 0 5px;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;
