type CsvDataRow = Array<string>;
type CsvCheckReturn = {
    success: boolean;
    error?: string;
};

export function checkProductRangeCsvImport({ csvData }: { csvData: Array<CsvDataRow> }): CsvCheckReturn {
    const [header, ...data] = csvData;
    const productIdIndex = header.findIndex((elem) => elem === 'productId');
    const productArrangementIdIndex = header.findIndex((elem) => elem === 'productArrangementId');
    const priceIndex = header.findIndex((elem) => elem === 'price');

    const arrayLength = header.length;

    if (!data.length) {
        return {
            success: false,
            error: 'Veuillez vérifier le fichier CSV. Il semble être vide',
        };
    }

    //if extra line in 2 column file, corrupts all data, last line - ['']
    if (data[data.length - 1].length === 1 && data[data.length - 1][0] === '') {
        return {
            success: false,
            error: "Fichier CSV mal formaté. Veuillez vérifier que vous n'avez pas laissé de lignes ou de colonnes vides",
        };
    }

    if ((productIdIndex === -1 && productArrangementIdIndex === -1) || priceIndex === -1) {
        return {
            success: false,
            error: "Veuillez vérifier les champs CSV. Il devrait y avoir 'productId' ou 'productArrangementId', et 'price'",
        };
    }

    let error;
    data.forEach((productArrayFromCSV: Array<string>, rowIndex: number): void => {
        if (productArrayFromCSV.length !== arrayLength) {
            error = `Veuillez vérifier le fichier CSV. Mauvais nombre de colonnes sur la ligne ${rowIndex + 2}`;
        }
    });

    data.forEach((productArrayFromCSV: Array<string>, rowIndex: number): void => {
        productArrayFromCSV.forEach((field: string, fieldIndex: number) => {
            if (!field) {
                error = `Veuillez vérifier le fichier CSV. Problème de champs sur la ligne ${rowIndex + 2}, colonne ${
                    fieldIndex + 1
                }`;
            }
        });
    });

    data.forEach((productArrayFromCSV: Array<string>, rowIndex: number): void => {
        const price = Number(productArrayFromCSV[priceIndex].replace(',', '.'));
        if (Number.isNaN(price) || Number(price) <= 0) {
            error = `Veuillez vérifier le fichier CSV. Prix nul ou non valide sur la ligne ${rowIndex + 2}`;
        }
    });

    data.forEach((productArrayFromCSV: Array<string>, rowIndex: number): void => {
        const formattedPrice = productArrayFromCSV[priceIndex].replace(',', '.');
        const [, cents] = formattedPrice.split('.');
        if (cents?.length && cents.length > 2)
            error = `Veuillez vérifier le fichier CSV. Prix avec trop de chiffres après la virgule sur la ligne ${
                rowIndex + 2
            }`;
    });

    return { success: !error, error };
}
