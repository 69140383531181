import React from 'react';
import styled from 'styled-components';
import type { FieldArrayWithId } from 'react-hook-form';

import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { RangePrice } from './TTCPrices';
import { GetProductPriceRangesQuery, PriceRangeType } from 'data/__generated__';

type PriceRangeFiels = FieldArrayWithId<
    {
        rangePrices: RangePrice[];
    },
    'rangePrices',
    'id'
>[];

export const PriceRangeSelector = ({
    append,
    fields,
    productPriceRanges,
}: {
    append: (
        value:
            | { rangeId: string; price: number; crossedPrice: number; type: PriceRangeType }
            | { rangeId: string; price: number; crossedPrice: number; type: PriceRangeType }[],
    ) => void;
    fields: PriceRangeFiels;
    productPriceRanges: GetProductPriceRangesQuery['productPriceRanges'];
}) => {
    const onSelect = (option: SelectedOption<string>) => {
        if (option && !fields.some(({ rangeId }) => rangeId === option.value)) {
            append({ rangeId: option.value, price: 0, crossedPrice: 0, type: PriceRangeType.Product });
        }
    };

    const productPriceOptions = productPriceRanges.map(({ _id, name }: { _id: string; name: string }) => ({
        value: _id,
        label: name,
    }));

    return (
        <SelectorContainer>
            <TotemSelect
                dataTest="select-product"
                label="Ajouter à une gamme de prix "
                placeholder="Sélectionner une gamme (recherche par nom ou par _id)"
                value={null}
                options={productPriceOptions}
                onChange={(option) => onSelect(option)}
                styles={customStyles}
            />
        </SelectorContainer>
    );
};

const SelectorContainer = styled.div`
    width: 100%;
    font-size: 15px;
`;

export const customStyles = {
    menuList: (provided: any) => ({
        ...provided,
        maxHeight: '110px',
    }),
    option: (provided: any) => ({
        ...provided,
        height: '30px',
    }),
};
