import React from 'react';

import styled from 'styled-components';

import { GetOrganizationQuery, OrganizationPaymentType, PaymentTimeType } from 'data/__generated__';

import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { DetailFormCheckbox, DetailFormSelect, DetailFormValue } from 'components/DetailsView';
import { PaymentType } from '../components/PaymentType';
import { useFormContext } from 'react-hook-form';

function getTranslatedPaymentTimeType(paymentTimeType: PaymentTimeType): string {
    switch (paymentTimeType) {
        case PaymentTimeType.EndOfMonth:
            return 'Fin du mois';
        case PaymentTimeType.NumberOfDay:
            return 'Nombre de jours';
    }
}

function getStripeLink(stripeId?: string | null): string {
    return 'https://dashboard.stripe.com/customers/' + stripeId;
}

export const PaymentInfoSection = ({ organization }: { organization: GetOrganizationQuery['organization'] }) => {
    const {
        automaticInvoicing,
        hasFreefoodIncluded,
        mandateSEPA,
        paymentTimeType,
        paymentTimeDays,
        paymentType,
        hasPrepaidOrganizationCards,
        hasPrepaidSubventions,
        segregatedInvoicing,
        stripeId,
    } = organization;

    const { register } = useFormContext();

    return (
        <SectionContainer title="Informations de facturation" isInitiallyOpen>
            <SectionColumn>
                <PaymentType paymentType={paymentType} />
                <DetailFormCheckbox
                    name="automaticInvoicing"
                    label="Facturation automatique"
                    defaultChecked={automaticInvoicing}
                />
                <DetailFormCheckbox
                    name="hasPrepaidOrganizationCards"
                    label="Facturation des badges pro au forfait"
                    defaultChecked={hasPrepaidOrganizationCards}
                />
                <DetailFormCheckbox
                    name="hasPrepaidSubventions"
                    label="Facturation des subventions au forfait"
                    defaultChecked={hasPrepaidSubventions}
                />
                <DetailFormCheckbox
                    name="segregatedInvoicing"
                    label="Séparer les factures freefood et abonnements"
                    defaultChecked={segregatedInvoicing}
                />

                <DetailFormCheckbox
                    name="hasFreefoodIncluded"
                    label="Freefood comprise dans le forfait (ne pas facturer les commandes)"
                    defaultChecked={hasFreefoodIncluded}
                />
                <DetailFormSelect
                    label="Moyen de paiement"
                    placeholder="Sélectionner un moyen de paiement"
                    defaultValue={paymentType}
                    name="paymentType"
                    options={Object.values(OrganizationPaymentType).map((value) => ({ value, label: value }))}
                />
                <DetailFormSelect
                    label="Type de délai de paiement"
                    placeholder="Sélectionner un type de délai de paiement"
                    defaultValue={paymentTimeType}
                    name="paymentTimeType"
                    options={Object.values(PaymentTimeType).map((value) => ({
                        value,
                        label: getTranslatedPaymentTimeType(value),
                    }))}
                />
                <DetailFormValue
                    defaultValue={paymentTimeDays}
                    type="number"
                    step="1"
                    min="0"
                    {...register('paymentTimeDays', { required: true, valueAsNumber: true })}
                    label="Nombre de jours de délai"
                />
                <InfoText>
                    Pour consulter ou mettre à jour les moyens de paiement et les informations de facturation, merci de
                    consulter Stripe.
                </InfoText>
                <ExternalResourceLink
                    href={getStripeLink(stripeId)}
                    target="_blank"
                    rel="noopener noreferrer"
                    disabled={!stripeId}
                >
                    <span>{stripeId ? 'Consulter les informations sur Stripe' : 'Pas de stripeId'}</span>
                </ExternalResourceLink>
                {mandateSEPA ? (
                    <MandateLink href={mandateSEPA} target="_blank" rel="noopener noreferrer">
                        Mandat SEPA
                    </MandateLink>
                ) : (
                    <span>Pas de mandat SEPA</span>
                )}
            </SectionColumn>
        </SectionContainer>
    );
};

const InfoText = styled.span`
    font-style: italic;
    color: ${({ theme }) => theme.textColor};
`;

const MandateLink = styled.a`
    display: flex;
    flex-direction: row;
    align-items: center;
    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
`;

const ExternalResourceLink = styled.a<{ disabled: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;

    color: ${({ theme }) => theme.ctaPrimaryColor};
    text-decoration: none;
    opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'default')};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

    &:hover {
        text-decoration: underline;
    }

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;
