import gql from 'graphql-tag';
import { MICROSTORE_LOCATION_FRAGMENT } from 'data/fragments/microstoreLocation';

export const MICROSTORE_COLUMN_FRAGMENT = gql`
    fragment MicrostoreColumnFragment on MicrostoreColumn {
        _id
        columnGroupId
        columnIdToReplace
        isPaused
        microstoreColumnTemplateId
        name
        positionInColumnGroup
        removalDate
        externalColumnId
        externalColumnType
        startingDate
        templateName
        type
    }
`;

export const MICROSTORE_COLUMN_WITH_SHELVES_FRAGMENT = gql`
    fragment MicrostoreColumnWithShelvesFragment on MicrostoreColumn {
        _id
        canBeSecured
        columnGroupId
        columnIdToReplace
        isPaused
        microstoreColumnTemplateId
        name
        positionInColumnGroup
        removalDate
        externalColumnId
        externalColumnType
        shelves {
            _id
            locationTable {
                ...MicrostoreLocationFragment
            }
            positionInColumn
        }
        startingDate
        templateName
        type
    }
    ${MICROSTORE_LOCATION_FRAGMENT}
`;
