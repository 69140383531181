import React, { useCallback, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useApolloClient, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { LocationsToUpdate, ProductReplacementPopupInfo } from './types';
import { GET_PRODUCT_ARRANGEMENT } from 'data/queries/__generated__/GET_PRODUCT_ARRANGEMENT';
import { GET_PRODUCT_ARRANGEMENT_QUERY } from 'data/queries/productArrangement';
import {
    GetCatalogProductsQuery,
    GetProductArrangementQuery,
    ProductState,
    useGetCatalogProductsLazyQuery,
} from 'data/__generated__';
import { CATALOG_PRODUCT_FRAGMENT } from 'data/fragments/product';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Loader } from 'components/Loader';
import { DetailDisplayValue } from 'components/DetailsView/DetailDisplayValue';
import { DetailFormAsyncSelect } from 'components/DetailsView';

type FormData = {
    newProductId: string | null;
};

export function ProductReplacementPopup({
    isOpen,
    locationsToUpdate,
    setIsOpen,
    productReplacementPopupInfo,
    updateLocationsToUpdate,
}: {
    isOpen: boolean;
    locationsToUpdate: LocationsToUpdate;
    setIsOpen: (arg: boolean) => void;
    productReplacementPopupInfo: ProductReplacementPopupInfo | null;
    updateLocationsToUpdate: ({
        locationId,
        newProduct,
        currentProductId,
        dayIndex,
    }: {
        locationId: string;
        newProduct:
            | GetProductArrangementQuery['productArrangementQuery']['productsWithStockMax'][0]['product']
            | GetCatalogProductsQuery['catalogProducts']['products'][0]
            | null;
        currentProductId: string | null;
        dayIndex: number;
    }) => void;
}) {
    const apolloClient = useApolloClient();

    const methods = useForm<FormData>();
    const {
        handleSubmit,
        formState: { isDirty },
        reset,
    } = methods;

    useEffect(() => {
        reset({ newProductId: null });
    }, [isOpen, reset]);

    const { locationInColumn } = productReplacementPopupInfo || {};
    const productArrangementId = locationInColumn?.microstoreLocationTemplate.productArrangementId;

    const { data, loading, error } = useQuery<GET_PRODUCT_ARRANGEMENT>(GET_PRODUCT_ARRANGEMENT_QUERY, {
        skip: !productArrangementId,
        variables: { productArrangementId },
    });

    const [fetchProducts] = useGetCatalogProductsLazyQuery();

    const onLoadOptions = useCallback(async (input: string) => {
        const searchProductsResult = await fetchProducts({
            variables: {
                filterString: input,
                offset: 0,
                stateFilter: [ProductState.Regular, ProductState.Created],
            },
        });
        if (!searchProductsResult?.data?.catalogProducts?.products) {
            toast.error('Erreur lors de la récupération des produits');
            return [];
        }
        const options = searchProductsResult.data.catalogProducts.products.map(({ _id, fullname }) => ({
            label: fullname,
            value: _id,
        }));

        return options;
    }, []);

    if (!productReplacementPopupInfo) {
        return null;
    }

    const { productLocation, dayIndex } = productReplacementPopupInfo;

    const currentProductName = productLocation?.product?.fullname;
    const currentProductId = productLocation?.product?._id || null;
    const locationId = locationInColumn?._id;

    const productArrangementName = data?.productArrangementQuery.name;
    const productArrangementProducts = data?.productArrangementQuery.productsWithStockMax || [];
    const productArrangementOptions = productArrangementProducts
        .filter(({ product }) => product?.state !== ProductState.Archived)
        .map(({ product }) => ({
            label: product.fullname,
            value: product._id,
        }));

    const removeProduct = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.preventDefault();
        setIsOpen(false);
        if (!locationId) {
            toast.error("Pas d'emplacement sélectionné - impossible");
            return;
        }
        updateLocationsToUpdate({
            locationId,
            newProduct: null,
            currentProductId,
            dayIndex,
        });
    };

    const onSubmit = handleSubmit((fields) => {
        const { newProductId } = fields;
        setIsOpen(false);
        if (!locationId) {
            toast.error("Pas d'emplacement sélectionné - impossible");
            return;
        }
        // new product might not be in product arrangement
        const newProduct =
            productArrangementProducts.find(({ product }) => product._id === newProductId)?.product ||
            apolloClient.readFragment({
                id: `Product:${newProductId}`,
                fragment: CATALOG_PRODUCT_FRAGMENT,
                fragmentName: 'CatalogProductFragment',
            });

        updateLocationsToUpdate({
            locationId,
            newProduct: newProduct || null,
            currentProductId,
            dayIndex,
        });
    });

    return (
        <TotemPopup
            title="Remplacer le produit dans cet emplacement"
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            contentOverflow="visible"
        >
            <FormProvider {...methods}>
                <Form onSubmit={onSubmit}>
                    {loading ? <Loader /> : null}
                    {/* @ts-expect-error to fix */}
                    {error ? <div>{error}</div> : null}
                    <DetailDisplayValue label="Produit actuel" value={currentProductName || 'Aucun produit'} />
                    <DetailDisplayValue label="Gamme de produit" value={productArrangementName} />
                    <DetailFormAsyncSelect
                        label="Nouveau produit"
                        name="newProductId"
                        placeholder="choisir un produit"
                        defaultOptions={productArrangementOptions}
                        loadOptions={onLoadOptions}
                    />
                    <SubmitContainer>
                        <TotemPrimaryButton
                            onClick={removeProduct}
                            isSecondaryStyle
                            disabled={(!currentProductName && locationId && !locationsToUpdate[locationId]) || loading}
                        >
                            Supprimer le produit
                        </TotemPrimaryButton>
                        <TotemPrimaryButton type="submit" disabled={!isDirty || loading}>
                            {productReplacementPopupInfo ? 'Confirmer' : 'Erreur - fermer'}
                        </TotemPrimaryButton>
                    </SubmitContainer>
                </Form>
            </FormProvider>
        </TotemPopup>
    );
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const SubmitContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
    width: 100%;
`;
