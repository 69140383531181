import React from 'react';

import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { StateHistory } from 'components/DetailsView';

import { StatusTag } from 'pages/Organizations/components/StatusTag';
import { GetOrganizationQuery } from 'data/__generated__';

export const StatesSection = ({ organization }: { organization: GetOrganizationQuery['organization'] }) => {
    const { state, stateHistory } = organization;
    const formattedStateHistory = stateHistory.map((stateRecord) => ({
        ...stateRecord,
        state: <StatusTag state={stateRecord.state} />,
    }));

    return (
        <SectionContainer title="Statuts et historique" isInitiallyOpen>
            <SectionColumn>
                <StatusTag state={state} showHeading />
                <StateHistory records={formattedStateHistory} />
            </SectionColumn>
        </SectionContainer>
    );
};
