import React, { useState } from 'react';

import { useNavigate, Link } from 'react-router-dom';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { FaTrash } from 'react-icons/fa';

import { colors } from 'constants/colors';

import { ProductsPriceTable } from './ProductsPriceTable';
import { PriceRangeProductSelector } from './PriceRangeProductSelector';
import { PriceRangeProductArrangementSelector } from './PriceRangeProductArrangementSelector';
import { ProductArrangementsPriceTable } from './ProductArrangementsPriceTable';

import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { Header, HeaderTitle } from 'components/Header';
import { Loader, LoaderModeType } from 'components/Loader';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { TotemCsvInputButton, CsvDataRow } from 'components/TotemCsvInputButton';

import { checkProductRangeCsvImport } from '../../helpers/CsvCheckers/checkProductRangeCsvImport';
import { useCreatePriceRangeMutation, useGetProductsFromCsvLazyQuery } from 'data/__generated__';

export type ProductEntry = {
    productId: string;
    price: number;
    crossedPrice: number | null;
    name: string;
};

export type ProductArrangementEntry = {
    productArrangementId: string;
    price: number;
    crossedPrice: number | null;
    name: string;
};

export type SelectedProduct = {
    productId: string;
    name: string;
};

export type ProductPriceRangeType = {
    rangeName: string;
    products: ProductEntry[];
    productArrangements: ProductArrangementEntry[];
};

export type SelectedProductArrangement = {
    productArrangementId: string;
    name: string;
};

export const ProductPriceRangeCreate = () => {
    const navigate = useNavigate();
    const [selectedProducts, setSelectedProducts] = useState<SelectedProduct[]>([]);
    const [selectedProductArrangements, setSelectedProductArrangements] = useState<SelectedProductArrangement[]>([]);
    const [csvData, setCsvData] = useState<Array<CsvDataRow>>([]);
    const [getProductsFromCsv, { loading: loadingCsv }] = useGetProductsFromCsvLazyQuery({
        fetchPolicy: 'cache-and-network',
        onCompleted({ productsFromCsv }) {
            const {
                productsForRange,
                idsNotFound,
                idsNotActive,
                productArrangementsForRange,
                productArrangementsIdsNotFound,
                productArrangementsIdsNotActive,
            } = productsFromCsv;
            const productsToAddToSelection = [];
            const productArrangementsToAddToSelection = [];
            const existingProductValues = getValues('products');
            const existingProductArrangementValues = getValues('productArrangements');

            for (const product of productsForRange) {
                const { _id, name, price } = product;
                if (price) {
                    if (selectedProducts.some(({ productId }) => productId === _id)) {
                        const index = existingProductValues.map(({ productId }) => productId).indexOf(_id);
                        setValue(`products.${index}.price` as 'products.0.price', price);
                    } else {
                        productsToAddToSelection.push({ productId: _id, name });
                        appendProduct({ productId: _id, price } as ProductEntry);
                    }
                }
            }
            setSelectedProducts([...selectedProducts, ...productsToAddToSelection]);

            for (const productArrangement of productArrangementsForRange) {
                const { _id, name, price } = productArrangement;
                if (price) {
                    if (selectedProductArrangements.some(({ productArrangementId }) => productArrangementId === _id)) {
                        const index = existingProductArrangementValues
                            .map(({ productArrangementId }) => productArrangementId)
                            .indexOf(_id);
                        setValue(`productArrangements.${index}.price` as 'productArrangements.0.price', price);
                    } else {
                        productArrangementsToAddToSelection.push({ productArrangementId: _id, name });
                        appendProductArrangement({ productArrangementId: _id, price } as ProductArrangementEntry);
                    }
                }
            }
            setSelectedProductArrangements([...selectedProductArrangements, ...productArrangementsToAddToSelection]);

            if (idsNotFound.length) {
                let message = 'Ids des produits pas trouvés: ';
                message += idsNotFound.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }

            if (idsNotActive.length) {
                let message = 'Ids des produits en état non-actif: ';
                message += idsNotActive.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }

            if (productArrangementsIdsNotFound.length) {
                let message = 'Ids des gammes de produits pas trouvés: ';
                message += productArrangementsIdsNotFound.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }

            if (productArrangementsIdsNotActive.length) {
                let message = 'Ids des gammes de produits en état non-actif: ';
                message += productArrangementsIdsNotActive.join(', ');
                toast.warn(message, { closeOnClick: false, autoClose: false });
            }
        },
    });

    const [createPriceRange, { loading: createPriceRangeLoading }] = useCreatePriceRangeMutation();

    const methods = useForm<ProductPriceRangeType>({ shouldUnregister: false });
    const {
        getValues,
        handleSubmit,
        formState: { isDirty },
        control,
        register,
        reset,
        setValue,
    } = methods;
    const {
        fields: productsFields,
        append: appendProduct,
        remove: removeProduct,
    } = useFieldArray({ control, name: 'products' });
    const {
        fields: productArrangementsFields,
        append: appendProductArrangement,
        remove: removeProductArrangement,
    } = useFieldArray({ control, name: 'productArrangements' });

    if (createPriceRangeLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    const handleCSVImport = async () => {
        const { success, error } = checkProductRangeCsvImport({ csvData });
        if (!success) {
            setCsvData([]);
            toast.error(error, { autoClose: false });
            return;
        }

        const [header, ...data] = csvData;
        const productIdIndex = header.findIndex((elem) => elem === 'productId');
        const productArrangementIdIndex = header.findIndex((elem) => elem === 'productArrangementId');
        const priceIndex = header.findIndex((elem) => elem === 'price');

        const csvDataFormatted = data.map((rowArray) => {
            const price = Number(rowArray[priceIndex].replace(',', '.'));
            return {
                productId: rowArray[productIdIndex],
                productArrangementId: rowArray[productArrangementIdIndex],
                price,
            };
        });

        await getProductsFromCsv({
            variables: {
                csvData: csvDataFormatted,
            },
        });
    };

    function handleRemoveProduct(index: number): void {
        removeProduct(index);
        setSelectedProducts(selectedProducts.filter((_, selectedProductIndex) => selectedProductIndex !== index));
    }

    function handleRemoveProductArrangement(index: number): void {
        removeProductArrangement(index);
        setSelectedProductArrangements(
            selectedProductArrangements.filter(
                (_, selectedProductArrangementIndex) => selectedProductArrangementIndex !== index,
            ),
        );
    }

    const onSubmit = handleSubmit(async (formData) => {
        const { rangeName, products, productArrangements = [] } = formData;

        if (!products?.length && !productArrangements?.length) {
            toast.error('Une gamme doit contenir au moins 1 produit ou 1 gamme de produit');
            return null;
        }

        reset({ products, productArrangements });

        const { data } = await createPriceRange({
            variables: {
                rangeName,
                products,
                productArrangements,
            },
        });

        if (data) {
            const { success, error, rangeId } = data.createPriceRange;
            if (success && rangeId) {
                toast.success(`La gamme "${rangeName}" a bien été créée !`);
                navigate('/productPriceRange/' + rangeId);
            }
            toast.error(error);
        } else {
            toast.error('Une erreur est survenue lors de la création de la gamme');
        }
    });

    return (
        <Container>
            <Header>
                <HeaderTitle>Créer une gamme de prix</HeaderTitle>
                <ButtonsContainer>
                    {csvData.length ? (
                        <ButtonsContainer>
                            <TotemPrimaryButton onClick={() => setCsvData([])}>
                                <FaTrash data-test="trash-icon" size={12} color={colors.pureWhite} />
                            </TotemPrimaryButton>
                            <TotemPrimaryButton minWidth="105px" onClick={handleCSVImport}>
                                {loadingCsv ? <Loader size="18px" mode={LoaderModeType.Spin} /> : 'Import CSV'}
                            </TotemPrimaryButton>
                        </ButtonsContainer>
                    ) : (
                        <TotemCsvInputButton onCsvDataUpload={setCsvData} />
                    )}

                    <Link to="/productPriceRanges">
                        <TotemPrimaryButton isSecondaryStyle>Retour</TotemPrimaryButton>
                    </Link>
                </ButtonsContainer>
            </Header>
            <Content>
                <FormProvider {...methods}>
                    <Form onSubmit={onSubmit}>
                        <Fields>
                            <DetailFormValue
                                label="Nom de la gamme"
                                placeholder="Gamme A, prix Pantin, prix Murex ..."
                                width="100%"
                                {...register('rangeName' as const, { required: true })}
                            />
                            <PriceRangeProductSelector
                                append={appendProduct}
                                selectedProducts={selectedProducts}
                                setSelectedProducts={setSelectedProducts}
                            />
                            <PriceRangeProductArrangementSelector
                                append={appendProductArrangement}
                                selectedProductArrangements={selectedProductArrangements}
                                setSelectedProductArrangements={setSelectedProductArrangements}
                            />
                        </Fields>
                        {productsFields.length ? (
                            <ProductsPriceTable
                                selectedProducts={selectedProducts}
                                fields={productsFields}
                                removeProduct={handleRemoveProduct}
                            />
                        ) : null}
                        {productArrangementsFields.length ? (
                            <ProductArrangementsPriceTable
                                selectedProductArrangements={selectedProductArrangements}
                                fields={productArrangementsFields}
                                removeProductArrangement={handleRemoveProductArrangement}
                            />
                        ) : null}
                        <RangeSubmitButton type="submit" disabled={!isDirty}>
                            Créer la gamme
                        </RangeSubmitButton>
                    </Form>
                </FormProvider>
            </Content>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background: ${({ theme }) => theme.backgroundColor};
`;

const ButtonsContainer = styled.div`
    display: flex;
    width: max-content;
    align-items: center;

    ${TotemPrimaryButton} {
        margin-left: 5px;
    }
`;

const Content = styled.div`
    display: flex;
    position: relative;
    flex: 1;
    padding: 15px;
    overflow-y: hidden;
`;

const Form = styled.form`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    align-items: center;
`;

const Fields = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    & > :not(:first-child) {
        margin-top: 10px;
    }
`;

const RangeSubmitButton = styled(TotemPrimaryButton)`
    margin-top: 10px;
`;
