import React from 'react';
import styled from 'styled-components';
import { Loader, LoaderModeType } from 'components/Loader';

import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { useGetOrganizationsQuery } from 'data/__generated__';

export function OrganizationSelector({
    selectedOption,
    setSelectedOption,
}: {
    selectedOption: SelectedOption<string>;
    setSelectedOption: (option: SelectedOption<string>) => void;
}) {
    const {
        loading: organizationsLoading,
        data: organizationsData,
        error: organizationsError,
    } = useGetOrganizationsQuery();

    if (organizationsLoading) {
        return <Loader mode={LoaderModeType.Spin} />;
    }

    if (organizationsError || !organizationsData) {
        throw new Error('Une erreur est survenue lors de la récupération des organisations');
    }

    const handleOrganizationSelect = (option: SelectedOption<string>) => {
        setSelectedOption(option);
    };

    const organizationsOptions = organizationsData.organizations
        .map((organization) => ({ value: organization._id, label: organization.name }))
        .sort((organizationA, organizationB) =>
            organizationA.label.toLowerCase().localeCompare(organizationB.label.toLowerCase()),
        );

    return (
        <Container>
            <TotemSelect
                label="Client"
                placeholder="Sélectionner un client (recherche par nom ou par _id)"
                value={selectedOption}
                options={organizationsOptions}
                onChange={handleOrganizationSelect}
            />
        </Container>
    );
}

// These lines are a placeholder for the select options
const Container = styled.div`
    min-width: 400px;
    min-height: 150px;
`;
