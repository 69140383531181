import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Loader } from 'components/Loader';

import { OrganizationGlobalForm } from './OrganizationGlobalForm';
import { GetOrganizationQuery, useGetOrganizationQuery } from 'data/__generated__';
type ParamTypes = {
    organizationId: string;
};

export const OrganizationDetails = () => {
    const { organizationId = '' } = useParams<ParamTypes>();
    const [organizationDetails, setOrganizationDetails] = useState<GetOrganizationQuery['organization'] | null>(null);

    const {
        loading: organizationLoading,
        error: organizationError,
        data: organizationData,
    } = useGetOrganizationQuery({
        variables: { organizationId },
    });

    useEffect(() => {
        if (organizationData) {
            const { organization } = organizationData;
            setOrganizationDetails(organization);
        }
    }, [organizationData]);

    if (organizationLoading || !organizationDetails) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (organizationError || !organizationData) {
        throw new Error('Une erreur est survenue lors de la récupération de cette organisation');
    }

    const organization = organizationData.organization;

    return (
        <Container>
            <OrganizationGlobalForm organization={organization} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
`;
