import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import { PAGES } from '../../constants/pages';
import { DAY_SELECT_OPTIONS, transformSundayFirstToMondayFirstDayIndex } from 'constants/dateAndTime';

import { GET_URBANTZ_ROUND_PRESETS_QUERY } from 'data/queries/urbantzRoundPreset';
import { GET_URBANTZ_ROUND_PRESETS } from 'data/queries/__generated__/GET_URBANTZ_ROUND_PRESETS';

import { GET_ACTIVE_TOTEMS } from 'data/queries/__generated__/GET_ACTIVE_TOTEMS';
import { GET_ACTIVE_TOTEMS_QUERY } from 'data/queries/totem';

import { Header, HeaderTitle } from 'components/Header';
import { Loader } from 'components/Loader';
import { PageTitle } from 'components/PageTitle';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { CreateUrbantzRoundPresetPopup } from './Popups/CreateUrbantzRoundPresetPopup';
import { CreateRoundsWithPresetsPopup } from './Popups/CreateRoundsWithPresetsPopup';
import { UrbantzRoundPresetForm } from './UrbantzRoundPresetForm';
import { Option, TotemSelect } from 'components/TotemSelect';
import { GET_SITES_QUERY } from 'data/queries/site';
import { GET_SITES } from 'data/queries/__generated__/GET_SITES';
import { SiteState, SiteType, useGetOrganizationsQuery } from 'data/__generated__';

export const UrbantzRoundPresets = () => {
    const [selectedDay, setSelectedDay] = useState<Option<number>>(DAY_SELECT_OPTIONS[0]);
    const [isCreateUrbantzRoundPresetPopupOpen, setIsCreateUrbantzRoundPresetPopupOpen] = useState<boolean>(false);
    const [isCreateRoundsWithPresetsPopupOpen, setIsCreateRoundsWithPresetsPopupOpen] = useState<boolean>(false);

    const {
        loading: urbantzRoundPresetsLoading,
        data: urbantzRoundPresetsData,
        error: urbantzRoundPresetsError,
    } = useQuery<GET_URBANTZ_ROUND_PRESETS>(GET_URBANTZ_ROUND_PRESETS_QUERY);

    const {
        loading: organizationsLoading,
        error: organizationsError,
        data: organizationsData,
    } = useGetOrganizationsQuery();

    const {
        loading: totemsLoading,
        error: totemsError,
        data: totemsData,
    } = useQuery<GET_ACTIVE_TOTEMS>(GET_ACTIVE_TOTEMS_QUERY);

    const { loading: sitesLoading, data: sitesData, error: sitesError } = useQuery<GET_SITES>(GET_SITES_QUERY);

    if (
        (urbantzRoundPresetsLoading && !urbantzRoundPresetsData) ||
        (organizationsLoading && !organizationsData) ||
        (sitesLoading && !sitesData) ||
        (totemsLoading && !totemsData)
    ) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (
        urbantzRoundPresetsError ||
        !urbantzRoundPresetsData ||
        organizationsError ||
        !organizationsData ||
        sitesError ||
        !sitesData ||
        totemsError ||
        !totemsData
    ) {
        throw new Error('Une erreur est survenue lors de la récupération des tournées prédéfinies');
    }

    const { getUrbantzRoundPresets: urbantzRoundPresets } = urbantzRoundPresetsData;
    const { organizations } = organizationsData;
    const { activeTotems: totems } = totemsData;
    const { sites } = sitesData;
    const stores = sites.filter(({ type, state }) => type === SiteType.Microstore && state === SiteState.Regular);
    const archivedStores = sites.filter(({ state }) => state === SiteState.Archived);

    const sortedUrbantzRoundPresets = [...urbantzRoundPresets].sort(
        (urbantzRoundPresetA, urbantzRoundPresetB) => urbantzRoundPresetA.createdAt - urbantzRoundPresetB.createdAt,
    );

    const filteredUrbantzRoundPresets = sortedUrbantzRoundPresets.filter(
        ({ dayIndex }) => dayIndex === selectedDay.value,
    );

    const totemOptions =
        totems
            .map(({ _id, organizationId, name, number, address }) => {
                const organization = organizations.find(({ _id }) => _id === organizationId);
                const store = stores.find(({ totemId }) => totemId === _id);
                return {
                    value: _id,
                    label: `${store ? `🏠 Store ${store.name} - ` : ''} ${
                        organization?.name || "Pas d'organisation"
                    } - ${name || `#${number}`} - ${address || "Pas d'adresse"}`,
                };
            })
            .sort((a, b) => a.label.localeCompare(b.label)) || [];

    const roundsTotemIds = filteredUrbantzRoundPresets
        .flatMap(({ totemIds }) => totemIds)
        .sort((totemIdA, totemIdB) => totemIdA.localeCompare(totemIdB));

    const mondayFirstSelectedDay = transformSundayFirstToMondayFirstDayIndex(selectedDay.value);
    const storesDeliveredAtSelectedDay = stores.filter(({ deliveryDays }) => deliveryDays[mondayFirstSelectedDay]);
    const storesNotDeliveredAtSelectedDay = stores.filter(({ deliveryDays }) => !deliveryDays[mondayFirstSelectedDay]);

    const notAssignedStores = storesDeliveredAtSelectedDay.filter(({ totemId }) => !roundsTotemIds.includes(totemId));
    const shouldNotBeAssignedStores = storesNotDeliveredAtSelectedDay.filter(({ totemId }) =>
        roundsTotemIds.includes(totemId),
    );
    const duplicatedTotemIds = roundsTotemIds.filter((totemId, index) => roundsTotemIds?.[index + 1] === totemId);

    const archivedStoreInDeliveries = archivedStores.filter(({ totemId }) => roundsTotemIds.includes(totemId));

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.urbantzRoundPresets} />
                </HeaderTitle>
                <ActionContainer>
                    <TotemPrimaryButton
                        onClick={() => {
                            setIsCreateUrbantzRoundPresetPopupOpen(true);
                        }}
                    >
                        Ajouter une tournée
                    </TotemPrimaryButton>
                    <TotemPrimaryButton
                        onClick={() => {
                            setIsCreateRoundsWithPresetsPopupOpen(true);
                        }}
                    >
                        Générer les tournées sur Urbantz
                    </TotemPrimaryButton>
                </ActionContainer>
            </Header>
            <Content>
                <TotemSelect
                    placeholder="Sélectionner un jour"
                    value={selectedDay}
                    options={DAY_SELECT_OPTIONS}
                    onChange={(option) => {
                        if (option) {
                            setSelectedDay(option);
                        }
                    }}
                />
                {archivedStoreInDeliveries.length ? (
                    <ErrorMessage>
                        Il y a des sites archivés dans les tournées :
                        <List>
                            {archivedStoreInDeliveries.map(({ _id, name }) => (
                                <ListElement key={_id}>• {name}</ListElement>
                            ))}
                        </List>
                    </ErrorMessage>
                ) : null}
                {notAssignedStores.length ? (
                    <WarningMessage>
                        Il y a des sites non attribués :
                        <List>
                            {notAssignedStores.map(({ _id, name }) => (
                                <ListElement key={_id}>• {name}</ListElement>
                            ))}
                        </List>
                    </WarningMessage>
                ) : null}
                {shouldNotBeAssignedStores.length ? (
                    <WarningMessage>
                        Il y a des sites qui ne devraient pas être attribués :
                        <List>
                            {shouldNotBeAssignedStores.map(({ _id, name }) => (
                                <ListElement key={_id}>• {name}</ListElement>
                            ))}
                        </List>
                    </WarningMessage>
                ) : null}
                {duplicatedTotemIds.length ? (
                    <ErrorMessage>
                        Il y a des totems attribués à deux rounds différents :
                        <List>
                            {duplicatedTotemIds.map((totemId, index) => {
                                const totemOption = totemOptions.find(({ value }) => value === totemId);
                                return (
                                    <ListElement key={totemId + index}>• {totemOption?.label ?? 'Erreur'}</ListElement>
                                );
                            })}
                        </List>
                    </ErrorMessage>
                ) : null}
                {filteredUrbantzRoundPresets.map((urbantzRoundPreset) => (
                    <UrbantzRoundPresetForm
                        key={urbantzRoundPreset._id}
                        totemOptions={totemOptions}
                        urbantzRoundPreset={urbantzRoundPreset}
                    />
                ))}
            </Content>
            <CreateUrbantzRoundPresetPopup
                isOpen={isCreateUrbantzRoundPresetPopupOpen}
                setIsOpen={setIsCreateUrbantzRoundPresetPopupOpen}
            />
            <CreateRoundsWithPresetsPopup
                isOpen={isCreateRoundsWithPresetsPopupOpen}
                setIsOpen={setIsCreateRoundsWithPresetsPopupOpen}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: auto;
`;

const ActionContainer = styled.div`
    display: flex;

    & > :not(:first-child) {
        margin-left: 10px;
    }
`;

const WarningMessage = styled.div`
    margin-top: 15px;
    color: ${({ theme }) => theme.warningColor};
    font-weight: bold;
`;

const ErrorMessage = styled.div`
    margin-top: 15px;
    color: ${({ theme }) => theme.errorColor};
    font-weight: bold;
`;

const List = styled.div`
    gap: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const ListElement = styled.div``;
