import React from 'react';
import { toast } from 'react-toastify';
import type { FieldArrayMethodProps } from 'react-hook-form';

import { ProductArrangementEntry, SelectedProductArrangement } from './ProductPriceRangeCreate';

import { SelectedOption, TotemSelect } from 'components/TotemSelect';
import { Loader, LoaderModeType } from 'components/Loader';
import { useGetProductArrangementsQuery } from 'data/__generated__';

export const PriceRangeProductArrangementSelector = ({
    append,
    selectedProductArrangements,
    setSelectedProductArrangements,
    shouldDisplaySelect = true,
}: {
    append: (
        value: ProductArrangementEntry | ProductArrangementEntry[],
        options?: FieldArrayMethodProps | undefined,
    ) => void;
    selectedProductArrangements: SelectedProductArrangement[];
    setSelectedProductArrangements: React.Dispatch<React.SetStateAction<SelectedProductArrangement[]>>;
    shouldDisplaySelect?: boolean;
}) => {
    const {
        loading: productArrangementsLoading,
        data: productArrangementsData,
        error: productArrangementsError,
    } = useGetProductArrangementsQuery();

    const onSelect = (option: SelectedOption<string>) => {
        if (
            option &&
            !selectedProductArrangements.some(({ productArrangementId }) => productArrangementId === option.value)
        ) {
            setSelectedProductArrangements([
                ...selectedProductArrangements,
                { productArrangementId: option.value, name: option.label },
            ]);
            append({ productArrangementId: option.value } as ProductArrangementEntry);
        }
    };

    if (productArrangementsLoading) {
        return <Loader mode={LoaderModeType.Spin} />;
    }

    if (productArrangementsError || !productArrangementsData) {
        toast.error('Une erreur est survenue lors de la récupération des produits non-archivés ');
        return null;
    }

    const { productArrangementsQuery: productArrangements } = productArrangementsData;

    const selectedProductArrangementIds = selectedProductArrangements.map(
        ({ productArrangementId }) => productArrangementId,
    );

    const productArrangementsOptions =
        productArrangements
            .filter(({ _id }) => !selectedProductArrangementIds.includes(_id))
            .map(({ _id, name }) => ({
                value: _id,
                label: name,
            })) || [];

    return shouldDisplaySelect ? (
        <TotemSelect
            dataTest="select-product-arrangement"
            label="Ajouter une gamme de produit"
            placeholder="Sélectionner une gamme de produit (recherche par nom ou par _id)"
            value={null}
            options={productArrangementsOptions}
            onChange={(option) => onSelect(option)}
        />
    ) : (
        <></>
    );
};
