import React from 'react';

import { FaTrash } from 'react-icons/fa';
import styled from 'styled-components';

import { TotemLabel } from 'components/TotemLabel';
import { TotemImage } from 'components/TotemImage';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import { TotemInput } from 'components/TotemInput';

import { ProductArrangementFormValues } from './FormHelper/DataMapper';
import { StatusTag } from 'pages/Products/components/StatusTag';
import { DetailLink } from 'components/DetailsView';
import { GET_ALL_PRODUCTS_getAllProducts } from 'data/queries/__generated__/GET_ALL_PRODUCTS';
import { colors } from 'constants/colors';

export const ProductsArrangementProductsTable = ({
    productArrangementFormValues,
    setProductArrangementFormValuesAndIsFormDirty,
    products,
}: {
    productArrangementFormValues: ProductArrangementFormValues;
    setProductArrangementFormValuesAndIsFormDirty: ({
        newProductArrangementFormValues,
        newIsDirty,
    }: {
        newProductArrangementFormValues: ProductArrangementFormValues;
        newIsDirty?: boolean | undefined;
    }) => void;
    products: GET_ALL_PRODUCTS_getAllProducts[];
}) => {
    function updateStockMaxOfProduct({
        productId,
        stockMaxUnsecured,
        stockMaxSensei,
    }: {
        productId: string;
        stockMaxUnsecured: number | null;
        stockMaxSensei: number | null;
    }): void {
        const newProductsWithStockMax = productArrangementFormValues.productsWithStockMax.map((productWithStockMax) =>
            productWithStockMax.product._id === productId
                ? { ...productWithStockMax, stockMaxUnsecured, stockMaxSensei }
                : productWithStockMax,
        );

        setProductArrangementFormValuesAndIsFormDirty({
            newProductArrangementFormValues: {
                ...productArrangementFormValues,
                productsWithStockMax: newProductsWithStockMax,
            },
        });
    }

    function removeProductFromProductArrangement({ productId }: { productId: string }): void {
        setProductArrangementFormValuesAndIsFormDirty({
            newProductArrangementFormValues: {
                ...productArrangementFormValues,
                productsWithStockMax: productArrangementFormValues.productsWithStockMax.filter(
                    (productWithStockMax) => productId !== productWithStockMax.product._id,
                ),
            },
        });
    }

    const categoryIds = productArrangementFormValues.productsWithStockMax.map(
        ({ product: { _id } }) => products.find((product) => product._id === _id)?.categoryId ?? 'noCategoryWeird',
    );
    const uniqueCategoryIds = Array.from(new Set([...categoryIds]));

    return (
        <Container>
            <TotemLabel>Produits de la gamme</TotemLabel>
            {uniqueCategoryIds.length > 1 ? (
                <WarningLabel>
                    La gamme possède des produits de catégories différentes. (id des categories :{' '}
                    {uniqueCategoryIds.join(', ')})
                </WarningLabel>
            ) : null}
            <ProductsTable>
                <thead>
                    <tr>
                        <ProductsTableHeaderCell />
                        <ProductsTableHeaderCell>Produit</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell>Est un produit microstore</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell>Statut du produit</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell isDataCentered>Stock max confiance</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell isDataCentered>Stock max sensei</ProductsTableHeaderCell>
                        <ProductsTableHeaderCell />
                    </tr>
                </thead>
                <tbody>
                    {productArrangementFormValues.productsWithStockMax.map((productWithStockMax, index) => {
                        const {
                            product: { _id: productId, brand, imageUrl, isMicrostore, name, state, volume },
                            stockMaxUnsecured,
                            stockMaxSensei,
                        } = productWithStockMax;

                        return (
                            <tr key={productId}>
                                <ProductsTableBodyCell>
                                    <TotemImage src={imageUrl} alt={name} size={50} />
                                </ProductsTableBodyCell>
                                <ProductsTableBodyCell>
                                    <DetailLink
                                        name={`${name} - ${brand} - ${volume}`}
                                        value={productId}
                                        path="product"
                                    />
                                </ProductsTableBodyCell>
                                <ProductsTableBodyCell>{isMicrostore ? 'Oui' : 'Non'}</ProductsTableBodyCell>
                                <ProductsTableBodyCell>
                                    <StatusTag state={state} />
                                </ProductsTableBodyCell>
                                <ProductsTableBodyCell>
                                    <TotemInput
                                        name={`productsWithStockMax.${index}.stockMaxUnsecured`}
                                        placeholder="Stock max confiance"
                                        type="number"
                                        centerText={true}
                                        step="1"
                                        min="1"
                                        onChange={(newStockMax) =>
                                            updateStockMaxOfProduct({
                                                productId,
                                                stockMaxUnsecured: newStockMax ? parseInt(newStockMax) : null,
                                                stockMaxSensei,
                                            })
                                        }
                                        value={stockMaxUnsecured?.toString() ?? ''}
                                        required
                                    />
                                </ProductsTableBodyCell>
                                <ProductsTableBodyCell>
                                    <TotemInput
                                        name={`productsWithStockMax.${index}.stockMaxSensei`}
                                        placeholder="Stock max sensei"
                                        type="number"
                                        centerText={true}
                                        step="1"
                                        min="1"
                                        onChange={(newStockMax) =>
                                            updateStockMaxOfProduct({
                                                productId,
                                                stockMaxUnsecured,
                                                stockMaxSensei: newStockMax ? parseInt(newStockMax) : null,
                                            })
                                        }
                                        value={stockMaxSensei?.toString() ?? ''}
                                        required
                                    />
                                </ProductsTableBodyCell>
                                <ProductsTableBodyCell isDataCentered>
                                    <TotemPrimaryButton
                                        type="button"
                                        onClick={() => removeProductFromProductArrangement({ productId })}
                                    >
                                        <FaTrash size={20} />
                                    </TotemPrimaryButton>
                                </ProductsTableBodyCell>
                            </tr>
                        );
                    })}
                </tbody>
            </ProductsTable>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductsTable = styled.table`
    margin-top: 10px;
    border-radius: ${({ theme }) => theme.borderRadius};
    border-spacing: 0px;
    border: 1px solid ${({ theme }) => theme.lightBorderColor};
`;

const ProductsTableHeaderCell = styled.th`
    padding: 10px;
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const ProductsTableBodyCell = styled.td`
    padding: 10px;
    border-top: 1px solid ${({ theme }) => theme.lightBorderColor};
    text-align: ${({ isDataCentered }: { isDataCentered?: boolean }) => (isDataCentered ? 'center' : 'left')};
`;

const WarningLabel = styled.div`
    color: ${colors.red};
    font-weight: bold;
`;
