import React, { useEffect } from 'react';

import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';

import { apolloClient } from 'App';

import { GET_ACTIVE_CATEGORIES } from 'data/queries/__generated__/GET_ACTIVE_CATEGORIES';
import { GET_ACTIVE_ORGANIZATIONS } from 'data/queries/__generated__/GET_ACTIVE_ORGANIZATIONS';
import { GET_LOCATIONS_FOR_SELECTION } from 'data/queries/__generated__/GET_LOCATIONS_FOR_SELECTION';
import { GET_TAGS } from 'data/queries/__generated__/GET_TAGS';

import { GET_ACTIVE_CATEGORIES_QUERY } from 'data/queries/category';
import { GET_ACTIVE_ORGANIZATIONS_QUERY } from 'data/queries/organization';
import { GET_DETAILED_PRODUCT_QUERY } from 'data/queries/product';
import { GET_LOCATIONS_FOR_SELECTION_QUERY } from 'data/queries/locations';
import { GET_TAGS_QUERY } from 'data/queries/tag';
import { UPDATED_PRODUCT_SUBSCRIPTION } from 'data/subscriptions/product';

import { updatedProductHandler } from 'pages/Products/productSubscriptionHandlers';

import { Loader } from 'components/Loader';
import { ProductGlobalForm } from './ProductGlobalForm';
import { GET_SUPPLIERS } from 'data/queries/__generated__/GET_SUPPLIERS';
import { GET_SUPPLIERS_QUERY } from 'data/queries/supplier';
import { GetDetailedProductQuery, useGetProductPriceRangesQuery, useGetProductPricesQuery } from 'data/__generated__';

type ParamTypes = {
    productId: string;
};

export const ProductDetails = () => {
    const { productId = '' } = useParams<ParamTypes>();

    useEffect(() => {
        const observer = apolloClient.subscribe({
            query: UPDATED_PRODUCT_SUBSCRIPTION,
            variables: { productId },
        });

        const subscription = observer.subscribe(({ data }) => {
            const { updatedProduct } = data;

            if (detailedProduct) {
                updatedProductHandler({ productId, detailedProduct, updatedProduct });
                toast.warn("Le produit vient d'être mis à jour");
            }
        });

        return () => subscription.unsubscribe();
    });

    const {
        loading: productLoading,
        data: productData,
        error: productError,
    } = useQuery<GetDetailedProductQuery>(GET_DETAILED_PRODUCT_QUERY, {
        variables: { productId },
    });

    const {
        loading: activeCategoriesLoading,
        data: activeCategoriesData,
        error: activeCategoriesError,
    } = useQuery<GET_ACTIVE_CATEGORIES>(GET_ACTIVE_CATEGORIES_QUERY);

    const { loading: tagsLoading, data: tagsData, error: tagsError } = useQuery<GET_TAGS>(GET_TAGS_QUERY);

    const {
        loading: productRangesLoading,
        data: productRangesData,
        error: productRangesError,
    } = useGetProductPriceRangesQuery();

    const {
        loading: productPricesLoading,
        data: productPricesData,
        error: productPricesError,
    } = useGetProductPricesQuery({
        variables: { productId },
    });

    const {
        loading: organizationsLoading,
        data: organizationsData,
        error: organizationsError,
    } = useQuery<GET_ACTIVE_ORGANIZATIONS>(GET_ACTIVE_ORGANIZATIONS_QUERY);

    const {
        loading: locationsLoading,
        data: locationsData,
        error: locationsError,
    } = useQuery<GET_LOCATIONS_FOR_SELECTION>(GET_LOCATIONS_FOR_SELECTION_QUERY);

    const {
        loading: suppliersLoading,
        data: suppliersData,
        error: suppliersError,
    } = useQuery<GET_SUPPLIERS>(GET_SUPPLIERS_QUERY);

    if (
        organizationsLoading ||
        productLoading ||
        productPricesLoading ||
        productRangesLoading ||
        activeCategoriesLoading ||
        tagsLoading ||
        locationsLoading ||
        suppliersLoading
    ) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (
        organizationsError ||
        !organizationsData ||
        productError ||
        !productData ||
        productRangesError ||
        !productRangesData ||
        productPricesError ||
        !productPricesData ||
        activeCategoriesError ||
        !activeCategoriesData ||
        tagsError ||
        !tagsData ||
        locationsError ||
        !locationsData ||
        suppliersError ||
        !suppliersData
    ) {
        throw new Error('Une erreur est survenue lors de la récupération du produit');
    }
    const { tags } = tagsData;
    const { detailedProduct } = productData;
    const { activeCategories } = activeCategoriesData;
    const { activeOrganizations } = organizationsData;
    const { productPriceRanges } = productRangesData;
    const { productPrices } = productPricesData;
    const { locationSelectOptions: locations } = locationsData;
    const { suppliers } = suppliersData;

    if (!detailedProduct) {
        throw new Error(`Aucun produit avec l'id ${productId} n'a été trouvé`);
    }

    return (
        <Container>
            <ProductGlobalForm
                tags={tags}
                categories={activeCategories}
                organizations={activeOrganizations}
                detailedProduct={detailedProduct}
                productPrices={productPrices}
                productPriceRanges={productPriceRanges}
                locations={locations}
                suppliers={suppliers}
            />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex: 1;
    background-color: ${({ theme }) => theme.backgroundColor};
`;
