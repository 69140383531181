export type FieldType = {
    name: string;
    displayInfo?: string;
};

export const REQUIRED_CREATION_FIELDS: FieldType[] = [
    { name: 'name' },
    { name: 'brand' },
    { name: 'volume' },
    { name: 'description' },
    { name: 'categoryId', displayInfo: ': id de catégorie existante' },
    { name: 'supplierId', displayInfo: ': id de supplier existant' },
    { name: 'flow', displayInfo: '(fresh, dry ou fruit)' },

    { name: 'supplierPrice' },
    { name: 'supplierConditionning' },
    { name: 'supplierPackagesPerBatch' },
    { name: 'tva', displayInfo: '(0.055, 0.1 ou 0.2)' },
    { name: 'conditionningTotem' },
    { name: 'portion' },

    {
        name: 'scan',
        displayInfo: "(true/false) scanner l'emplacement lors de la préparation de commande",
    },
];

export const CONDITIONAL_FIELDS: FieldType[] = [
    { name: 'weightWithoutPackaging', displayInfo: ': requis si displayKg est true, sinon - optionnel' },
    { name: 'priceB2B', displayInfo: ': requis si isFreefood ou isPunctual est true, sinon - optionnel' },
    { name: 'priceB2C', displayInfo: ': requis si isMicrostore est true, sinon - optionnel' },
    { name: 'crossedPriceB2B', displayInfo: ': optionnel si priceB2B est défini' },
    { name: 'crossedPriceB2C', displayInfo: ': optionnel si priceB2C est défini' },
];

export const CONDITIONAL_MODIFICATION_FIELDS: FieldType[] = [];

export const OPTIONAL_CREATION_FIELDS: FieldType[] = [
    { name: 'weight', displayInfo: '(kg)' },
    { name: 'height', displayInfo: '(cm)' },
    { name: 'width', displayInfo: '(cm)' },
    { name: 'length', displayInfo: '(cm)' },
    { name: 'isFreefood', displayInfo: '(true/false)' },
    { name: 'isMicrostore', displayInfo: '(true/false)' },
    { name: 'shouldBeHiddenInStore', displayInfo: '(true/false)' },
    { name: 'isPunctual', displayInfo: '(true/false)' },
    { name: 'displayKg', displayInfo: '(true/false)' },
    { name: 'isTitreRestaurantAllowed', displayInfo: '(true/false)' },
    { name: 'isScannable', displayInfo: '(true/false)' },
    { name: 'allowNegativeStock', displayInfo: "Ne pas vérifier le stock disponible dans l'entrepôt (true/false)" },
    { name: 'hasShortExpiryDate', displayInfo: 'DLC courte (true/false)' },
    { name: 'trackExpiryDate', displayInfo: 'Monitoring des DLC (true/false)' },

    { name: 'badgeIds', displayInfo: ': ids des badges séparés par une virgule' },
    { name: 'barcodes', displayInfo: ": codes-barres séparées par une virgule (type 'Produit' mis par défaut)" },

    { name: 'comment', displayInfo: ': commentaires' },

    { name: 'toleranceMin', displayInfo: 'Écart toléré en dessous du poids (g)' },
    { name: 'toleranceMax', displayInfo: 'Écart toléré au dessus du poids (g)' },

    { name: 'indexDisplay', displayInfo: "Index d'affichage" },
    { name: 'ingredients' },
    { name: 'nutriscore' },
    { name: 'supplierCode' },
    { name: 'allergenCanContain' },
    {
        name: 'allergens',
        displayInfo:
            ": tous les allergens sont mis à 'false' par défaut. Pour en mettre certains à 'true', utilisez les mot-clés (celery, crustaceans, eggs, fish, gluten, lupin, milk, molluscs, mustard, nuts, peanuts, sesame, soybeans, sulphites) séparés par une virgule",
    },
    { name: 'nutritionCaloriesKCal' },
    { name: 'nutritionCarbohydrates' },
    { name: 'nutritionFibers' },
    { name: 'nutritionProtein' },
    { name: 'nutritionSodium' },
    { name: 'nutritionSturatedFat' },
    { name: 'nutritionSugars' },
    { name: 'nutritionTotalFat' },
    { name: 'stockThreshold', displayInfo: 'Coefficient de réapprovisionnement' },
    { name: 'stockThresholdMinimum', displayInfo: 'Stock de réserve minimal' },
    { name: 'subcategoryId', displayInfo: ': id de sous-catégorie existante' },
    { name: 'tagIds', displayInfo: ': ids des tags séparés par une virgule' },
    {
        name: 'deliveryDays',
        displayInfo:
            ": tous les jours de livraison sont mis à 'true' par défaut. Pour en mettre certaines à 'false', utilisez les mot-clés (monday, tuesday, wednesday, thursday, friday, saturday) séparés par une virgule",
    },
    { name: 'minSupplierExpiryDays', displayInfo: 'Nombre de jours avant DLC (garantie fournisseur)' },
    { name: 'searchKeywords', displayInfo: 'Mots-clés de recherche séparés par une virgule' },
];

export const REQUIRED_UPDATE_FIELDS: FieldType[] = [{ name: '_id' }];
export const OPTIONAL_UPDATE_FIELDS = [
    { name: 'name' },
    { name: 'externalProductId' },
    { name: 'brand' },
    { name: 'volume' },
    { name: 'description' },
    { name: 'categoryId' },
    {
        name: 'supplierId',
        displayInfo:
            '(Si supplierId, supplierCode, supplierPrice, supplierConditionning, supplierPackagesPerBatch alors on ajoute un nouveau supplier en position 1. Sinon on modifie le supplier en position 1)',
    },
    {
        name: 'supplierCode',
        displayInfo: '(modifie le premier supplierCode du supplier ou ajoute un nouveau supplier en position 1)',
    },
    {
        name: 'supplierPrice',
        displayInfo: '(modifie le premier supplierPrice du supplier ou ajoute un nouveau supplier en position 1)',
    },
    {
        name: 'supplierConditionning',
        displayInfo:
            '(modifie le premier supplierConditionning du supplier ou ajoute un nouveau supplier en position 1)',
    },
    {
        name: 'supplierPackagesPerBatch',
        displayInfo:
            '(modifie le premier supplierPackagesPerBatch du supplier ou ajoute un nouveau supplier en position 1)',
    },
    { name: 'flow', displayInfo: '(fresh, dry ou fruit)' },
    { name: 'tva', displayInfo: '(0.055, 0.1, 0.2)' },

    { name: 'comment', displayInfo: ': commentaires' },
    { name: 'isFreefood', displayInfo: '(true/false)' },
    { name: 'isMicrostore', displayInfo: '(true/false)' },
    { name: 'shouldBeHiddenInStore', displayInfo: '(true/false)' },
    {
        name: 'onboardedForSensei',
        displayInfo: 'onboarder ce produit pour Sensei (true: lance la synchronisation des produits avec sensei)',
    },
    { name: 'isPunctual', displayInfo: '(true/false)' },
    { name: 'displayKg,', displayInfo: '(true/false)' },
    { name: 'isTitreRestaurantAllowed', displayInfo: ' (true/false)' },
    { name: 'isScannable', displayInfo: ' (true/false) : only EAN' },
    { name: 'allowNegativeStock', displayInfo: "Ne pas vérifier le stock disponible dans l'entrepôt (true/false)" },
    { name: 'hasShortExpiryDate', displayInfo: 'DLC courte (true/false)' },
    { name: 'trackExpiryDate', displayInfo: 'Monitoring des DLC (true/false)' },
    {
        name: 'scan',
        displayInfo: "(true/false) scanner l'emplacement lors de la préparation de commande",
    },

    { name: 'portion' },
    { name: 'conditionningTotem' },
    { name: 'weightWithoutPackaging' },
    { name: 'weight', displayInfo: '(kg)' },
    { name: 'height', displayInfo: '(cm)' },
    { name: 'width', displayInfo: '(cm)' },
    { name: 'length', displayInfo: '(cm)' },
    { name: 'toleranceMin', displayInfo: 'Écart toléré en dessous du poids (g)' },
    { name: 'toleranceMax', displayInfo: 'Écart toléré au dessus du poids (g)' },
    { name: 'badgeIds', displayInfo: ': ids des badges séparés par une virgule' },
    { name: 'subcategoryId', displayInfo: ': id de sous-catégorie existante' },
    { name: 'tagIds', displayInfo: ': ids des tags séparés par une virgule' },
    { name: 'barcodes', displayInfo: ": codes-barres séparées par une virgule (type 'Produit' mis par défaut)" },
    { name: 'indexDisplay', displayInfo: "Index d'affichage" },
    { name: 'ingredients' },
    { name: 'isToBeArchived', displayInfo: '(true/false)' },
    { name: 'nutriscore' },
    { name: 'allergenCanContain' },
    {
        name: 'allergens',
        displayInfo:
            ": tous les allergens sont mis à 'false' par défaut. Pour en mettre certains à 'true', utilisez les mot-clés (celery, crustaceans, eggs, fish, gluten, lupin, milk, molluscs, mustard, nuts, peanuts, sesame, soybeans, sulphites) séparés par une virgule",
    },

    { name: 'nutritionCaloriesKCal' },
    { name: 'nutritionCarbohydrates' },
    { name: 'nutritionFibers' },
    { name: 'nutritionProtein' },
    { name: 'nutritionSodium' },
    { name: 'nutritionSturatedFat' },
    { name: 'nutritionSugars' },
    { name: 'nutritionTotalFat' },

    { name: 'stockThreshold', displayInfo: 'Coefficient de réapprovisionnement' },
    { name: 'stockThresholdMinimum', displayInfo: 'Stock de réserve minimal' },
    {
        name: 'deliveryDays',
        displayInfo:
            ": tous les jours de livraison sont mis à 'true' par défaut. Pour en mettre certaines à 'false', utilisez les mot-clés (monday, tuesday, wednesday, thursday, friday, saturday) séparés par une virgule",
    },
    { name: 'minSupplierExpiryDays', displayInfo: 'Nombre de jours avant DLC (garantie fournisseur)' },
    { name: 'searchKeywords', displayInfo: 'Mots-clés de recherche séparés par une virgule' },
];

export const ID = '_id';

export const NUMERIC_FIELDS = [
    'supplierPrice',
    'supplierConditionning',
    'supplierPackagesPerBatch',
    'tva',
    'conditionningTotem',
    'portion',
    'weight',
    'height',
    'width',
    'length',
    'toleranceMin',
    'toleranceMax',
    'weightWithoutPackaging',
    'priceB2B',
    'priceB2C',
    'crossedPriceB2B',
    'crossedPriceB2C',
    'indexDisplay',
    'nutritionCaloriesKCal',
    'nutritionCarbohydrates',
    'nutritionFibers',
    'nutritionProtein',
    'nutritionSodium',
    'nutritionSturatedFat',
    'nutritionSugars',
    'nutritionTotalFat',
    'stockThreshold',
    'stockThresholdMinimum',
    'minSupplierExpiryDays',
];

export const NUMERIC_FIELDS_THAT_CAN_BE_NULL = ['crossedPriceB2B', 'crossedPriceB2C'];

export const BOOLEAN_FIELDS = [
    'allowNegativeStock',
    'displayKg',
    'isFreefood',
    'isMicrostore',
    'shouldBeHiddenInStore',
    'onboardedForSensei',
    'isPunctual',
    'isScannable',
    'isToBeArchived',
    'isTitreRestaurantAllowed',
    'hasShortExpiryDate',
    'scan',
    'trackExpiryDate',
];

//fields that exist in the csv import but not DB
export const CUSTOM_CREATION_FIELDS = [
    'allergencancontain',
    'supplierid',
    'supplierprice',
    'supplierconditionning',
    'supplierpackagesperbatch',
    'suppliercode',
];

export const CUSTOM_FIELDS = [
    'nutritioncalorieskcal',
    'nutritioncarbohydrates',
    'nutritionfibers',
    'nutritionprotein',
    'nutritionsodium',
    'nutritionsturatedfat',
    'nutritionsugars',
    'nutritiontotalfat',
    'allergens',
    'deliverydays',
    'barcodes',
    'badgeids',
    'priceb2b',
    'priceb2c',
    'crossedpriceb2b',
    'crossedpriceb2c',
    'tagids',
];

export const REQUIRED_ACTIVE_PRODUCT_FIELDS = [
    'weight',
    'height',
    'width',
    'length',
    'toleranceMin',
    'toleranceMax',
    'isFreefood',
    'isMicrostore',
    'shouldBeHiddenInStore',
    'isPunctual',
    'indexDisplay',
];
