import { apolloClient } from 'App';

import { GET_CATALOG_PRODUCTS_QUERY, GET_DETAILED_PRODUCT_QUERY } from 'data/queries/product';
import { UPDATE_PRODUCT_updateProduct_updatedProduct } from 'data/mutations/__generated__/UPDATE_PRODUCT';
import { UPDATED_PRODUCTS_updatedProducts } from 'data/subscriptions/__generated__/UPDATED_PRODUCTS';
import { GetCatalogProductsQuery, GetDetailedProductQuery } from 'data/__generated__';

export const updatedProductsHandler = ({
    products,
    updatedProducts,
}: {
    products: GetCatalogProductsQuery['catalogProducts']['products'];
    updatedProducts: UPDATED_PRODUCTS_updatedProducts[];
}) => {
    let currentProducts = products || ([] as GetCatalogProductsQuery['catalogProducts']['products']);
    // We can read query only if it has been run
    try {
        const result = apolloClient.readQuery<GetCatalogProductsQuery>({ query: GET_CATALOG_PRODUCTS_QUERY });
        // const result = useGetCatalogProductsQuery();
        if (!result) {
            throw Error('An error occurred when reading GET_CATALOG_PRODUCTS_QUERY');
        }
        currentProducts = result.catalogProducts.products;
    } catch {
        // do nothing
    }

    const updatedProductIds = updatedProducts.map(({ _id }) => _id);
    const nonUpdatedProducts = currentProducts.filter(({ _id }) => !updatedProductIds.includes(_id));
    const data = { catalogProducts: { products: [...updatedProducts, ...nonUpdatedProducts] } };

    apolloClient.writeQuery({
        query: GET_CATALOG_PRODUCTS_QUERY,
        data,
    });
};

export function updatedProductHandler({
    productId,
    detailedProduct,
    updatedProduct,
}: {
    detailedProduct: GetDetailedProductQuery['detailedProduct'];
    updatedProduct: UPDATE_PRODUCT_updateProduct_updatedProduct;
    productId: string;
}) {
    let currentProduct: GetDetailedProductQuery['detailedProduct'] = detailedProduct;
    // We can read query only after it has been run
    try {
        const result = apolloClient.readQuery<GetDetailedProductQuery>({
            query: GET_DETAILED_PRODUCT_QUERY,
            variables: { productId },
        });
        if (!result) {
            throw Error('An error occurred when reading GET_DETAILED_PRODUCT_QUERY');
        }
        currentProduct = result.detailedProduct;
    } catch {
        // do nothing
    }

    if (!updatedProduct) {
        return;
    }

    const data = { detailedProduct: { ...currentProduct, ...updatedProduct } };

    apolloClient.writeQuery({
        query: GET_DETAILED_PRODUCT_QUERY,
        variables: { productId },
        data,
    });
}
