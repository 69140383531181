import React, { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';

import styled from 'styled-components';
import { FaTrash } from 'react-icons/fa';

import { ErrorType, FORM_ERRORS, MIN } from '../../../constants/formErrors';
import { colors } from 'constants/colors';

import { TotemLabel } from 'components/TotemLabel';
import { DetailFormValue } from 'components/DetailsView/DetailFormValue';
import { PriceRangeSelector } from './components/PriceRangeSelector';
import { RangePrice, TTCPrices } from './components/TTCPrices';
import { GetProductPriceRangesQuery, GetProductPricesQuery, PriceRangeType } from 'data/__generated__';

export const ProductPrices = ({
    productPrices,
    productPriceRanges,
    tva,
    supplierPrice,
}: {
    productPrices?: GetProductPricesQuery['productPrices'];
    productPriceRanges: GetProductPriceRangesQuery['productPriceRanges'];
    tva: number;
    supplierPrice: number;
}) => {
    const {
        register,
        setValue,
        watch,
        formState: { errors },
    } = useFormContext();

    //need to provide default values in **useForm** to automatically type rangePrices
    const { fields, append, remove } = useFieldArray<
        {
            rangePrices: { rangeId: string; price: number; crossedPrice: number; type: PriceRangeType }[];
        },
        'rangePrices',
        'id'
    >({ name: 'rangePrices' as const });

    useEffect(() => {
        if (productPrices) {
            const productPriceFields = productPrices.map(({ rangeId, price, crossedPrice, type }) => ({
                rangeId,
                price,
                crossedPrice,
                type,
            }));
            setValue('rangePrices', productPriceFields);
        }
    }, [productPrices, setValue]);

    const handleRangeRemove = (index: number) => {
        remove(index);
    };

    const updatedTva: number = watch('tva', tva);

    const updatedRangePrices: RangePrice[] = watch(
        'rangePrices',
        productPrices?.map(({ rangeId, price, crossedPrice }) => ({
            rangeId,
            price,
            crossedPrice: crossedPrice || undefined,
        })) || [],
    );

    return (
        <>
            <PricesTitle>Les prix par gamme</PricesTitle>
            {fields?.length ? (
                <PricesSection>
                    <HTPricesContainer>
                        {fields.map((item, index) => {
                            const { id: fieldId, rangeId, price, crossedPrice, type } = item;
                            const rangeName = productPriceRanges.find(({ _id }) => _id === rangeId)?.name || '';

                            // @ts-expect-error don't know what's going on here, this is my last day
                            const errorType: ErrorType | undefined = errors?.rangePrices?.[index]?.price?.type;
                            const numberValue = errorType === MIN ? '0.01' : '';

                            const watchedPrice = updatedRangePrices?.[index]?.price;

                            const disabled = type === PriceRangeType.ProductArrangement;

                            return (
                                <PriceContainer key={fieldId}>
                                    <input
                                        {...register(`rangePrices.${index}.rangeId`)}
                                        type="hidden"
                                        defaultValue={rangeId}
                                    />

                                    <DetailFormValue
                                        label={`${rangeName} HT`}
                                        placeholder="?"
                                        defaultValue={price}
                                        type="number"
                                        step=".001"
                                        width="100%"
                                        customError={errorType && FORM_ERRORS[errorType] + numberValue}
                                        disabled={disabled}
                                        {...register(`rangePrices.${index}.price`, {
                                            min: 0.01,
                                            required: true,
                                            valueAsNumber: true,
                                        })}
                                    />

                                    <DetailFormValue
                                        label="Prix barré HT"
                                        placeholder="?"
                                        defaultValue={crossedPrice}
                                        type="number"
                                        step=".001"
                                        width="100%"
                                        disabled={disabled}
                                        {...register(`rangePrices.${index}.crossedPrice`, {
                                            required: false,
                                            setValueAs: (value) => (!value ? null : Number(value)),
                                            validate: (crossedPrice) =>
                                                crossedPrice === 0 || (crossedPrice && !Number.isNaN(crossedPrice))
                                                    ? crossedPrice > watchedPrice || `Doit être > ${watchedPrice}€`
                                                    : true,
                                        })}
                                    />

                                    <IconContainer
                                        onClick={() => !disabled && handleRangeRemove(index)}
                                        $disabled={disabled}
                                    >
                                        <TrashIcon data-test="trash-icon" size={16} color={colors.pureWhite} />
                                    </IconContainer>

                                    <TTCPrices
                                        updatedRangePrices={updatedRangePrices}
                                        updatedTva={updatedTva}
                                        item={item}
                                        index={index}
                                        supplierPrice={supplierPrice}
                                    />
                                </PriceContainer>
                            );
                        })}
                    </HTPricesContainer>
                </PricesSection>
            ) : null}
            <AddContainer>
                <PriceRangeSelector fields={fields} productPriceRanges={productPriceRanges} append={append} />
            </AddContainer>
        </>
    );
};

const PricesSection = styled.div`
    display: flex;
    position: relative;
    width: 100%;
`;

const AddContainer = styled.div`
    display: flex;
    width: 100%;
    height: 180px; // selector + selector menuList heights (to see select results inside collapsible)
    border-radius: ${({ theme }) => theme.borderRadius};
`;

const PricesTitle = styled(TotemLabel)`
    font-size: 16px;
`;

export const HTPricesContainer = styled.div`
    display: flex;
    flex: 2 1 100%;
    flex-direction: column;
`;

const PriceContainer = styled.div`
    display: flex;
    align-items: flex-end;
`;

const TrashIcon = styled(FaTrash)`
    color: ${({ theme }) => theme.menuTextColor};
`;

const IconContainer = styled.div<{ $disabled?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${({ theme, $disabled }) => ($disabled ? theme.disabledColor : theme.ctaPrimaryColor)};
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};
    margin: 10px;
    opacity: ${({ $disabled }) => ($disabled ? 0.6 : 1)};
`;
