import gql from 'graphql-tag';
import { PRODUCT_ARRANGEMENT_FRAGMENT } from './productArrangement';

export const PRODUCT_WITH_STOCK_VARIATIONS_FRAGMENT = gql`
    fragment ProductWithStockVariations on SiteProductWithVariations {
        product {
            _id
            fullname
            stock
            lastSiteInventoryStock {
                _id
                value
                createdAt
                createdBy
                createdByName
            }
        }
        availableStock {
            isUnlimited
            value
            details {
                dailyStocks
                orderedStocks
                realRecurringOrders
                predictionRecurringOrders
                realPunctualOrders
                predictionPunctualOrders
                realStoreTargetNotPrepared
                realStoreTargetPrepared
                predictionStoreTarget
                storeMenu
                preparationDate
                preparationDateUsedIndex
                soonestRestockedDate
                soonestRestockedDateUsedIndex
                usedRecurringOrders
                usedPunctualOrders
                usedStoreTarget
                usedStoreMenu
            }
        }
        variations {
            _id
            createdAt
            delta
            type
            ref
            explanation
            siteInventoryStockId
        }
    }
`;

export const FORM_ERROR_FRAGMENT = gql`
    fragment FormErrorFragment on FormError {
        sectionName
        sectionErrors {
            fieldName
            fieldError
        }
    }
`;

const PRODUCT_PRODUCT_ARRANGEMENT_FRAGMENT = gql`
    fragment ProductProductArrangementFragment on Product {
        _id
        productArrangements {
            ...ProductArrangementFragment
        }
    }
    ${PRODUCT_ARRANGEMENT_FRAGMENT}
`;

export const ADDITIONAL_PRODUCT_INFO_FRAGMENT = gql`
    fragment AdditionalProductInfoFragment on Product {
        description
        flow
        indexDisplay
        isFreefood
        isMicrostore
        shouldBeHiddenInStore
        isPunctual
        isFromNayax
        isWithoutStock
        nbRatings
        rating
        scan
        searchKeywords
    }
`;

export const DIMENSIONS_PRODUCT_INFO_FRAGMENT = gql`
    fragment DimensionsProductInfoFragment on Product {
        height
        length
        toleranceMin
        toleranceMax
        weight
        weightWithoutPackaging
        width
    }
`;

export const NUTRITION_PRODUCT_INFO_FRAGMENT = gql`
    fragment NutritionProductInfoFragment on Product {
        allergen {
            gluten
            crustaceans
            eggs
            fish
            peanuts
            soybeans
            milk
            nuts
            celery
            mustard
            sesame
            sulphites
            lupin
            molluscs
            canContain
        }
        ingredients
        nutriscore
        nutrition {
            caloriesKCal
            totalFat
            sturatedFat
            carbohydrates
            sugars
            fibers
            protein
            sodium
        }
    }
`;

export const GENERAL_PRODUCT_INFO_FRAGMENT = gql`
    fragment GeneralProductInfoFragment on Product {
        externalProductId
        barcodes {
            barcode
            type
        }
        brand
        category {
            _id
            title
        }
        createdAt
        imageUrl
        name
        packagingImageUrl
        state
        subcategory {
            _id
            name
        }
        updatedAt
        volume
    }
`;

export const SALES_PRODUCT_INFO_FRAGMENT = gql`
    fragment SalesProductInfoFragment on Product {
        conditionningTotem
        displayKg
        isScannable
        isTitreRestaurantAllowed
        onboardedForSensei
        portion
        proportion
        proportionMax
        supplierShortageInfo {
            inShortage
            returnDate
        }
        supplierShortageHistory {
            createdAt
            endedAt
        }
        suppliers {
            _id
            name
            code
            prices {
                value
                conditionning
                packagesPerBatch
            }
        }
        tva
    }
`;

export const STATE_HISTORY_PRODUCT_INFO_FRAGMENT = gql`
    fragment StateHistoryProductInfoFragment on Product {
        access
        isBeingTested
        isPaused
        isToBeArchived
        toBeArchivedHistory {
            createdAt
            endedAt
        }
        newUntil
        state
        stateHistory {
            state
            createdAt
            createdBy
            createdByName
        }
        useInMenusFrom
    }
`;

export const STOCK_AND_SUPPLIER_PRODUCT_INFO_FRAGMENT = gql`
    fragment StockAndSupplierProductInfoFragment on Product {
        allowNegativeStock
        canExpire
        checkSwollenLid
        deliveryDays {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
        }
        hasShortExpiryDate
        minSupplierExpiryDays
        stockThreshold
        stockThresholdMinimum
        trackExpiryDate
    }
`;

export const TAGS_AND_BADGES_PRODUCT_INFO_FRAGMENT = gql`
    fragment TagsAndBadgesProductInfoFragment on Product {
        tagIds
        preferences {
            _id
            name
            type
            imageUrl
        }
        diets {
            _id
            name
            type
            imageUrl
        }
    }
`;

export const PRODUCT_FRAGMENT = gql`
    fragment ProductFragment on Product {
        _id
        ...AdditionalProductInfoFragment
        ...DimensionsProductInfoFragment
        ...GeneralProductInfoFragment
        ...NutritionProductInfoFragment
        ...SalesProductInfoFragment
        ...StateHistoryProductInfoFragment
        ...StockAndSupplierProductInfoFragment
        ...TagsAndBadgesProductInfoFragment
        ...ProductProductArrangementFragment
    }
    ${ADDITIONAL_PRODUCT_INFO_FRAGMENT}
    ${DIMENSIONS_PRODUCT_INFO_FRAGMENT}
    ${GENERAL_PRODUCT_INFO_FRAGMENT}
    ${NUTRITION_PRODUCT_INFO_FRAGMENT}
    ${SALES_PRODUCT_INFO_FRAGMENT}
    ${STATE_HISTORY_PRODUCT_INFO_FRAGMENT}
    ${STOCK_AND_SUPPLIER_PRODUCT_INFO_FRAGMENT}
    ${TAGS_AND_BADGES_PRODUCT_INFO_FRAGMENT}
    ${PRODUCT_PRODUCT_ARRANGEMENT_FRAGMENT}
`;

export const CATALOG_PRODUCT_FRAGMENT = gql`
    fragment CatalogProductFragment on Product {
        _id
        access
        brand
        category {
            title
        }
        isBeingTested
        imageUrl
        isOutOfStock
        isPaused
        isToBeArchived
        name
        fullname
        newUntil
        state
        subcategory {
            name
        }
        suppliers {
            name
            prices {
                value
                conditionning
            }
        }
        supplierShortageInfo {
            inShortage
        }
        tags {
            name
        }
        updatedAt
        volume
    }
`;
