import React, { useEffect } from 'react';

import styled from 'styled-components';

import { formatPrice } from '@totem/constants';
import { apolloClient } from 'App';

import { UPDATED_PRODUCTS_SUBSCRIPTION } from 'data/subscriptions/product';
import { InfoType, ProductState, useGetCatalogProductsQuery } from 'data/__generated__';

import { FieldToDisplay, ListView } from 'components/ListView';
import { Loader } from 'components/Loader';
import { TotemImage } from 'components/TotemImage';
import { StatusTag } from '../components/StatusTag';
import { InfoTags } from 'pages/Products/components/InfoTags';
import { updatedProductsHandler } from 'pages/Products/productSubscriptionHandlers';

type FormattedProductType = {
    _id: string;
    state: JSX.Element;
    info: JSX.Element;
    name: string;
    categoryTitle: JSX.Element;
    image: JSX.Element;
    supplierName: string;
    supplierPrice: string;
    tags: string;
};

export const ProductsList = ({
    infoFilters,
    filterString,
    stateFilter,
    categoryFilter,
    subcategoryFilter,
    supplierFilter,
    tagFilter,
}: {
    infoFilters?: InfoType[];
    filterString?: string;
    stateFilter?: ProductState[];
    categoryFilter?: string[];
    subcategoryFilter?: string[];
    supplierFilter?: string[];
    tagFilter?: string[];
}) => {
    const {
        loading: productsLoading,
        data: productsData,
        error: productsError,
        fetchMore: fetchMoreProducts,
    } = useGetCatalogProductsQuery({
        variables: {
            offset: 0,
            filterString,
            stateFilter,
            categoryFilter,
            subcategoryFilter,
            supplierFilter,
            tagFilter,
            infoFilters,
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        const observer = apolloClient.subscribe({
            query: UPDATED_PRODUCTS_SUBSCRIPTION,
        });

        const subscription = observer.subscribe(({ data }) => {
            const { updatedProducts } = data;

            if (products) {
                updatedProductsHandler({ products, updatedProducts });
            }
        });

        return () => subscription.unsubscribe();
    });

    if (productsLoading && !productsData) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (productsError || !productsData) {
        throw new Error('Une erreur est survenue lors de la récupération des produits');
    }

    const {
        catalogProducts: { hasMore, products },
    } = productsData;

    const loadMore = async () => {
        await fetchMoreProducts({
            variables: {
                offset: products.length,
            },
        });
    };

    const PRODUCT_FIELDS_TO_DISPLAY: FieldToDisplay<FormattedProductType>[] = [
        { fieldName: 'image', label: 'Photo' },
        {
            fieldName: 'name',
            label: 'Nom',
        },
        {
            fieldName: 'state',
            label: 'Statut',
        },
        {
            fieldName: 'info',
            label: 'Info',
        },
        {
            fieldName: 'categoryTitle',
            label: 'Catégorie',
        },
        {
            fieldName: 'tags',
            label: 'Tag(s)',
        },
        {
            fieldName: 'supplierName',
            label: 'Fournisseur #1',
        },
        {
            fieldName: 'supplierPrice',
            label: "Prix d'achat",
        },
    ];

    const sortedProducts = [...products].sort((productA, productB) =>
        productA.updatedAt > productB.updatedAt ? -1 : 1,
    );

    const formattedProducts: FormattedProductType[] = sortedProducts.map(
        ({
            _id,
            access,
            isBeingTested,
            isOutOfStock,
            isPaused,
            isToBeArchived,
            name,
            newUntil,
            volume,
            brand,
            category: { title: categoryTitle },
            subcategory,
            state,
            imageUrl,
            suppliers,
            supplierShortageInfo,
            tags,
        }) => {
            let calculatedFullName = name;

            if (brand) {
                calculatedFullName += ` - ${brand}`;
            }
            if (volume) {
                calculatedFullName += ` - ${volume}`;
            }

            const { name: supplierName, prices = [] } = suppliers[0] || {};
            const { value: supplierPrice = '' } = prices[0] || {};

            return {
                _id,
                state: <StatusTag state={state} />,
                info: (
                    <InfoTags
                        access={access}
                        isToBeArchived={isToBeArchived}
                        isBeingTested={isBeingTested}
                        isOutOfStock={isOutOfStock}
                        newUntil={newUntil}
                        isPaused={isPaused}
                        showHeader={false}
                        isInShortage={supplierShortageInfo.inShortage}
                        shouldAddMargin={true}
                    />
                ),
                name: calculatedFullName,
                categoryTitle: (
                    <div>
                        {categoryTitle}
                        {subcategory ? (
                            <SubcategoryContainer>
                                {'\n'} ↳ <div>{subcategory.name}</div>
                            </SubcategoryContainer>
                        ) : (
                            ''
                        )}
                    </div>
                ),
                image: <TotemImage src={imageUrl || ''} alt={name} size={50} />,
                supplierName: supplierName || '',
                supplierPrice: supplierPrice !== '' ? formatPrice(supplierPrice) : supplierPrice,
                tags: tags.map(({ name }) => name).join(', '),
            };
        },
    );

    return (
        <ListView<FormattedProductType>
            fieldsToDisplay={PRODUCT_FIELDS_TO_DISPLAY}
            items={formattedProducts}
            keyExtractor={(item) => item._id}
            loadMore={loadMore}
            hasMore={hasMore}
            linkBasePath="/product/"
        />
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
`;

const SubcategoryContainer = styled.div`
    display: flex;
    flex-direction: row;
`;
