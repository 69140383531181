import React, { useState } from 'react';

import styled from 'styled-components';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { PAGES } from '../../constants/pages';
import { colors } from 'constants/colors';

import { TrelloCardInfoPopup } from 'pages/SupplyOrders/Popups/TrelloCardInfoPopup';
import { Header, HeaderTitle } from 'components/Header';
import { PageTitle } from 'components/PageTitle';
import { TotemDatePicker } from 'components/TotemDatePicker';
import { SupplyOrdersTrelloView } from './SupplyOrdersTrelloView';
import { TotemSelect, Option } from 'components/TotemSelect';
import { useGetSuppliersQuery } from 'data/__generated__';
import { useSearchParams } from 'react-router-dom';
import type { Options } from 'react-select';
import { TotemSwitch } from 'components/TotemSwitch';
import { TotemLabel } from 'components/TotemLabel';

type ParamTypes = {
    supplier?: string;
    filterDelayed?: boolean;
};

type UrlParams<T> = { [P in keyof T]?: string[] };

function parseUrlSearchParams<T>(searchParams: URLSearchParams): UrlParams<T> {
    const params: UrlParams<T> = {};
    // @ts-expect-error ignore URLSearchParams is not templated, key is string even with Location<T>
    searchParams.forEach((value: string, key: keyof T) => {
        if (!params[key]) {
            params[key] = [value];
        } else {
            (params[key] as string[]).push(value);
        }
    });
    return params;
}

function buildUrlSearchParams<T>(params: UrlParams<T>): URLSearchParams {
    return Object.entries(params).reduce((urlSearchParams, [key, values]) => {
        (values as string[]).forEach((value) => urlSearchParams.append(key, value));
        return urlSearchParams;
    }, new URLSearchParams());
}

export const SupplyOrders = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const params = parseUrlSearchParams<ParamTypes>(searchParams);

    const { supplier: supplierFilter, filterDelayed: filterDelayedFilter } = params;

    const oneWeekAgo = new Date();
    oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
    const [displayLimitDate, setDisplayLimitDate] = useState<Date>(oneWeekAgo);
    const [trelloCardInfoPopup, setTrelloCardInfoPopup] = useState(false);

    const {
        loading: suppliersLoading,
        data: suppliersData,
        error: suppliersError,
    } = useGetSuppliersQuery({
        fetchPolicy: 'cache-and-network',
    });

    const supplierOptions = (suppliersData?.suppliers || []).map((supplier) => ({
        label: supplier.name,
        value: supplier._id,
    }));

    const supplierOptionSelected = supplierOptions.filter(({ value }) => supplierFilter?.includes(value));
    const filterDelayedSelected = filterDelayedFilter?.includes('true') || false;

    if (!suppliersLoading && (suppliersError || !suppliersData)) {
        throw new Error('Une erreur est survenue lors de la récupération des fournisseurs');
    }

    function updateSupplierFilter(suppliers: Options<Option<string>> | null | undefined) {
        if (suppliers && suppliers?.length > 0) {
            const searchParams = buildUrlSearchParams({
                ...params,
                supplier: suppliers.map((supplier) => supplier.value),
            });
            setSearchParams(searchParams);
        } else {
            const searchParams = buildUrlSearchParams({ ...params, supplier: [] });
            setSearchParams(searchParams);
        }
    }

    function updateFilterDelayed(filterDelayed: boolean) {
        const searchParams = buildUrlSearchParams({
            ...params,
            filterDelayed: [filterDelayed ? 'true' : 'false'],
        });
        setSearchParams(searchParams);
    }

    return (
        <Container>
            <Header>
                <HeaderTitle>
                    <PageTitle page={PAGES.supplyOrders} />
                </HeaderTitle>
                <Filters>
                    {!suppliersLoading && !suppliersError ? (
                        <SelectContainer>
                            <TotemSelect
                                isMulti
                                placeholder="Fournisseur (nom ou _id)"
                                value={supplierOptionSelected}
                                options={supplierOptions}
                                onChange={updateSupplierFilter}
                            />
                        </SelectContainer>
                    ) : null}
                    <LabelContainer>
                        <Label>En retard</Label>
                        <TotemSwitch
                            checked={filterDelayedSelected}
                            onChange={() => updateFilterDelayed(!filterDelayedSelected)}
                            onColor={colors.green}
                            offColor={colors.pantinGrey}
                            checkedIcon={false}
                            uncheckedIcon={false}
                        />
                    </LabelContainer>
                </Filters>
                <SideContainer>
                    <InfoIcon size={25} onClick={() => setTrelloCardInfoPopup(true)} />
                    <DateContainer>
                        <TotemDatePicker
                            label="Date limite affichage rangés et annulés"
                            selected={displayLimitDate}
                            onChange={(date) => {
                                setDisplayLimitDate(date as Date);
                            }}
                        />
                    </DateContainer>
                </SideContainer>
            </Header>
            <Content>
                <SupplyOrdersTrelloView
                    displayLimitDate={displayLimitDate}
                    supplierIds={supplierFilter}
                    filterDelayed={filterDelayedSelected}
                />
            </Content>
            <TrelloCardInfoPopup setIsOpen={setTrelloCardInfoPopup} isOpen={trelloCardInfoPopup} />
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
`;

const DateContainer = styled.div`
    min-width: 300px;
    justify-self: end;
`;

const SideContainer = styled.div`
    display: flex;
    align-items: center;
`;

const InfoIcon = styled(AiOutlineInfoCircle)`
    margin-right: 15px;
    cursor: pointer;
    color: ${colors.blue};
    z-index: 1;

    &:hover {
        transform: scale(1.1);
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px;
    flex: 1;
    overflow: hidden;
`;

const Filters = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    & > :not(:first-child) {
        margin-left: 5px;
    }
`;

const SelectContainer = styled.div`
    width: 500px;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: 12px;
`;

const Label = styled(TotemLabel)`
    margin-right: 5px;
`;
