import React, { useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useForm, FormProvider } from 'react-hook-form';

import { formatDateAsAnniversary } from 'helpers/dateTimes';

import { TotemPopup } from 'components/TotemPopup';
import { TotemPrimaryButton } from 'components/TotemPrimaryButton';
import {
    SiteState,
    SiteType,
    useGenerateMenusSupplyOrdersMutation,
    useGetSitesQuery,
    useGetSuppliersQuery,
} from 'data/__generated__';
import { Loader, LoaderModeType } from 'components/Loader';
import { DetailFormCheckbox, DetailFormSelect } from 'components/DetailsView';

type FormData = {
    selectedSiteIds: string[];
    selectedSupplierIds: string[];
    updateOrderProductIfPossible: boolean;
};

export function GenerateMenusSupplyOrdersButton({ date }: { date: Date }) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [generateMenusSupplyOrders, { loading }] = useGenerateMenusSupplyOrdersMutation();

    const methods = useForm<FormData>({
        defaultValues: {
            selectedSiteIds: [],
            selectedSupplierIds: [],
            updateOrderProductIfPossible: false,
        },
    });

    const {
        loading: sitesLoading,
        data: sitesData,
        error: sitesError,
    } = useGetSitesQuery({
        fetchPolicy: 'cache-and-network',
    });

    const {
        loading: suppliersLoading,
        data: suppliersData,
        error: suppliersError,
    } = useGetSuppliersQuery({
        fetchPolicy: 'cache-and-network',
    });

    if (sitesLoading || suppliersLoading) {
        return (
            <Container>
                <Loader />
            </Container>
        );
    }

    if (!sitesLoading && (sitesError || !sitesData)) {
        throw new Error('Une erreur est survenue lors de la récupération des sites');
    }

    if (!suppliersLoading && (suppliersError || !suppliersData)) {
        throw new Error('Une erreur est survenue lors de la récupération des fournisseurs');
    }

    const siteOptions = (sitesData?.sites || [])
        .filter((site) => site.state !== SiteState.Archived && site.type === SiteType.Microstore)
        .map(({ _id, name }) => ({
            label: name,
            value: _id,
        }));

    const supplierOptions = (suppliersData?.suppliers || []).map(({ _id, name }) => ({
        label: name,
        value: _id,
    }));

    const onSubmit = methods.handleSubmit(async (formData) => {
        try {
            await generateMenusSupplyOrders({
                variables: {
                    date: formatDateAsAnniversary({ dateTime: date, useNewFormat: true }),
                    siteIds: formData.selectedSiteIds,
                    supplierIds: formData.selectedSupplierIds,
                    updateOrderProductIfPossible: formData.updateOrderProductIfPossible,
                },
                onCompleted: ({ generateMenusSupplyOrders }) => {
                    if (generateMenusSupplyOrders?.success) {
                        toast.success('Les commandes fournisseurs ont bien été créées');
                        setIsOpen(false);
                    } else {
                        toast.error(
                            `Une erreur s'est produite : ${generateMenusSupplyOrders?.error || 'erreur inconnue'}`,
                        );
                    }
                },
                onError: (error) => {
                    toast.error(`Impossible de créer les commandes fournisseur : ${error}`);
                },
            });
        } catch (error) {
            toast.error(`Impossible de créer les commandes fournisseur : ${error}`);
            throw Error('Impossible de créer les commandes fournisseur');
        }
    });

    return (
        <>
            <TotemPopup
                title="Confirmer la création des achats"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                contentOverflow="visible"
            >
                <FormProvider {...methods}>
                    <form onSubmit={onSubmit}>
                        <FormContent>
                            <DetailFormSelect<string>
                                label="Site(s) concerné(s) (tous par défaut)"
                                placeholder="Sélectionnez les sites concernés"
                                isMulti
                                isClearable
                                name="selectedSiteIds"
                                options={siteOptions}
                            />
                            <DetailFormSelect<string>
                                label="Fournisseur(s) (tous par défaut)"
                                placeholder="Sélectionnez les fournisseurs concernés"
                                isMulti
                                isClearable
                                name="selectedSupplierIds"
                                options={supplierOptions}
                            />
                            <DetailFormCheckbox
                                label="Mettre à jour les cartes achats existantes"
                                name="updateOrderProductIfPossible"
                                defaultChecked={false}
                            />
                            <TotemPrimaryButton type="submit" disabled={loading}>
                                {loading ? <Loader size="18px" mode={LoaderModeType.Spin} /> : 'Confirmer'}
                            </TotemPrimaryButton>
                        </FormContent>
                    </form>
                </FormProvider>
            </TotemPopup>
            <TotemPrimaryButton disabled={loading} onClick={() => setIsOpen(true)}>
                Achats
            </TotemPrimaryButton>
        </>
    );
}

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};
    overflow: hidden;
`;

const FormContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
