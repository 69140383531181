import React from 'react';

import { useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';

import { GET_TOTEAM_USERS } from 'data/queries/__generated__/GET_TOTEAM_USERS';
import { GET_TOTEAM_USERS_QUERY } from 'data/queries/user';

import { SectionColumn, SectionContainer } from 'components/DetailsView/Section';
import { DetailFormSelect, DetailFormValue, DetailLink, DetailValue } from 'components/DetailsView';
import { Loader } from 'components/Loader';

import { getExtendedFormattedDatetime } from 'helpers/dateTimes';
import { GetOrganizationQuery } from 'data/__generated__';

export const AdditionalInfoSection = ({ organization }: { organization: GetOrganizationQuery['organization'] }) => {
    const { sales, termsAcceptanceDate, maxUsers, totalCoworkers } = organization;

    const {
        loading: salesLoading,
        data: salesData,
        error: salesError,
    } = useQuery<GET_TOTEAM_USERS>(GET_TOTEAM_USERS_QUERY);

    const { register } = useFormContext();

    if (salesLoading) {
        return (
            <SectionContainer title="Informations générales" isInitiallyOpen>
                <Loader />
            </SectionContainer>
        );
    }

    if (salesError || !salesData) {
        throw new Error('Une erreur est survenue lors de la récupération des membres de la Toteam');
    }

    const { toteamUsers } = salesData;
    const salesOptions = toteamUsers.map(({ _id, profile: { fullname } }) => ({
        label: fullname || 'Inconnu',
        value: _id,
    }));

    return (
        <SectionContainer title="Informations supplémentaires" isInitiallyOpen>
            <SectionColumn>
                <DetailValue
                    label="CGV"
                    value={
                        termsAcceptanceDate
                            ? `Acceptées le ${getExtendedFormattedDatetime(termsAcceptanceDate)}`
                            : 'Non acceptées'
                    }
                />
                <DetailFormSelect
                    label="Sales"
                    placeholder="Sélectionner un sales"
                    defaultValue={sales?._id}
                    isClearable={true}
                    name="salesId"
                    options={salesOptions}
                />
                {sales ? (
                    <DetailLink path="user" name={`Plus d'infos sur ${sales.fullName}`} value={sales._id} />
                ) : null}
                <DetailFormValue
                    defaultValue={maxUsers}
                    type="number"
                    step="1"
                    min="1"
                    {...register('maxUsers', { required: true, valueAsNumber: true })}
                    label="Nombre d'utilisateurs max"
                    sublabel="Nombre d'utilisateurs qui peuvent utiliser la WEBAPP sur cette organisation"
                />
                <DetailFormValue
                    defaultValue={totalCoworkers}
                    {...register('totalCoworkers', { valueAsNumber: true, required: true })}
                    type="number"
                    step="1"
                    min="0"
                    label="Nombre de collaborateurs"
                />
            </SectionColumn>
        </SectionContainer>
    );
};
